.api-wrapper {
  padding: 50px 0;

  .api-holder {
    padding: 20px;
    margin-bottom: 30px;

    background: #fffeed;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 5px;

    p {
      margin-bottom: 10px;

      color: #f5a624;
    }

    .call-title {
      margin: 0;

      font-weight: 300;
    }
  }

  .ui.celled.table {
    tbody {
      tr {
        td {
          width: 50%;

          .api-add-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .input-wrapper {
            flex: 0 0 100%;

            .ui.input {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.create__webhook {
  border-width: 0px !important;
  .error-message {
    font-size: 10px;
    color: red;
  }

  .ui.checkbox label {
    padding-left: 22px !important;
    font-size: 14.3px !important;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .settings {
    .api_wrapper_main {
      table.ui.celled.table {
        display: block;
      }
    }
  }
  body .creat_web_hook {
    width: 90% !important;
    .footer {
      button {
        margin-right: 6px !important;
      }
    }
  }
}
.btn-close {
  padding: 10px 20px;
  font-family: $code-font-stack;
  font-weight: 300;
  color: #fff;
  text-transform: capitalize;
  background: $link-color;
  border: 1px solid $link-color;
  letter-spacing: 1px;
  font-size: 14px;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $link-color;

    background: transparent;
  }
}
