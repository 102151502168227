div.checklist-popup.hide-menu,
div.checklist-popup {
  width: 16vw;
  background-color: #fff;
  z-index: 1000;
  position: fixed;
  right: 0;
  -webkit-box-shadow: 0 0 5px 2px #f7f7f7;
  -moz-box-shadow: 0 0 5px 2px #f7f7f7;
  box-shadow: 0 0 5px 2px #f7f7f7;
  border-bottom-left-radius: 10px 10px;
  border-top-left-radius: 20px 20px;
  transition: width 1s;
  padding-bottom: 20px;
  &.disable{
    display: none;
  }

  .header {
    background-color: #0071eb;
    height: 50px;
    border-top-left-radius: 20px 20px;
    color: #fff;
    margin-left: 0 !important;
  }

  .extentd-show {
    visibility: visible;
    display: block;
  }

  .extentd-hide {
    visibility: hidden;
    display: none;
  }

  .row {
    display: flex;
    align-items: center;
    vertical-align: center;
    // text-align: center;

    .col-item {
      flex: 0 0 auto;
      display: flex;
    }

    .item-1 {
      width: 15%;
      margin-left: 6px;

      p {
        display: block;
        padding: 0 30px;
      }
    }

    .item-2 {
      width: 50%;
    }
  }

  .round {
    position: relative;
  }

  .round label {
    background-color: #e6ebf4;
    border: 2px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 34px;
    left: 0;
    position: absolute;
    top: 0;
    width: 34px;
  }

  .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: '';
    height: 4px;
    left: 8px;
    opacity: 1;
    position: absolute;
    top: 12px;
    transform: rotate(-45deg);
    width: 18px;
  }

  .round input[type='checkbox'] {
    visibility: hidden;
  }

  .round input[type='checkbox']:checked+label {
    background-color: #0071eb;
    border-color: #0071eb;
  }

  .round input[type='checkbox']:checked+label:after {
    border-color: #fff !important;
  }

  .round input[type='checkbox']:not(:checked)+label:after {
    border-color: #bdc8d9 !important;
  }

  .content {
    .checklist-item {
      position: relative;
      left: 30px;
      height: 48px;

      .text {
        color: #b5bcc5;
        margin-top: 12px;
        font-size: 1.2rem;
      }

      .badge {
        margin-top: 12px;
        border-radius: 100%;
        padding: 10px;
        background: #f7f9fd;
        border: 2px solid #f7f9fd;
        text-align: center;
        font-weight: 400;
      }
    }

    .checklist-item-active {
      position: relative;
      left: 30px;
      height: 48px;

      .text {
        color: #808080;
        margin-top: 12px;
        font-size: 1.2rem;
        text-decoration: line-through;
      }

      .badge {
        margin-top: 12px;
        border-radius: 100% !important;
        padding: 10px !important;
        background: #0071eb;
        border: 2px solid #0071eb;
        color: #fff;
        text-align: center;
        font-weight: 400;
        display: inline-block;
      }
    }
  }
}
div.checklist-popup.hide-menu {
  width: 3.5vw !important;
}

.cursor-pointer svg,
.cursor-pointer {
  cursor: pointer;
}

div.checklist-popup:hover {
  // width: 24vw;

  .extentd-show {
    visibility: visible;
    display: flex;
  }

}

div.checklist-popup {
  top: 15%;
}

@media only screen and (max-width: 1600px) and (min-width: 1200px) {
  div.checklist-popup {
    width: 24vw;
  }

  div.checklist-popup:hover {
    width: 24vw;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 800px) {
  div.checklist-popup {
    width: 30vw;
  }

  div.checklist-popup:hover {
    width: 30vw;
  }
}

@media only screen and (max-width: 799px) and (min-width: 641px) {
  div.checklist-popup {
    width: 40vw;
  }

  div.checklist-popup:hover {
    width: 40vw;
  }
}

@media only screen and (max-width: 640px) {
  div.checklist-popup {
    width: 60vw;
  }

  div.checklist-popup:hover {
    width: 60vw;
  }
}

@media only screen and (max-height: 768px) {
  div.checklist-popup {
    top: 8% !important;
  }
}