#widget-preview-page{
   padding: 20px 40px; 
   display: flex;
    flex-grow: 1;
   
   .fixed-holder{
       position: fixed;
       top: 40vh;
        width: 25%;

       .row{
           display: flex;
           flex-direction: column;
             
             #form-preview{              
                min-height: 60vh;    
                
                .custom-widget-wrapper {

                  .custom-widget-wrapper {                    
                    padding: 5px 25px;                 
                  }
                 
                  top: 50px;
                  left: 0;
                  width: 100%;
                  max-width: 500px;
                  background-color: $white;
                  border-radius: 5px;               
            
                  &-smart {
                    border-top: 3px solid rgb(102, 45, 145);
                  }            
                  &-option {
                    top: 0;
                    left: 0;
                  }
            
                  &-head {
                    top: 120px;
                  }
            
                  .custom-widget-body {
                    position: static !important;
                  }
            
                  .custom-widget-header {
                    border-bottom: 1px solid $gray150;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
            
                    >div {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      flex-grow: 1;
                      margin: 10px 0;
                      padding: 5px 15px;
                      border-right: 1px solid $gray150;
                      text-align: center;
            
                      &:last-child {
                        border: none;
            
                        .close {
                          position: absolute;
                          right: 0;
                          top: 5px;
                          width: 10px;
                          height: 10px;
            
                          &::after,
                          &::before {
                            position: absolute;
                            left: 0;
                            content: ' ';
                            height: 10px;
                            width: 2px;
                            background-color: $black;
                          }
            
                          &::before {
                            transform: rotate(45deg);
                          }
            
                          &::after {
                            transform: rotate(-45deg);
                          }
                        }
                      }
            
                      img {
                        margin-right: 10px;
                        height: 15px;
                      }
            
                      p {
                        font-weight: 300;
                        font-size: 14px;
                        margin: 0;
                      }
                    }
                  }
            
                  .custom-widget-body {
                    // padding: 15px 40px;
            
                    h4 {
                      font-size: 24px;
                      text-align: center;
                    }
            
                    .custom-widget-body-h1 {
                      font-size: 21px;
                      font-weight: 600;
                      color: #000;
                      margin: 25px ​0 15px;
            
                    }
            
                    .call-me-btn {
                      text-align: center;
                      text-transform: uppercase;
                      background-color: $link-color;
                      color: $white;
                      padding: 10px;
                      font-size: 16px;
                      margin-top: 10px;
                      letter-spacing: 2px;
                    }
            
                    .custom-widget-content {
                      .widget-drop {
                        border: 1px solid $gray150;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        font-weight: 300;
                        font-size: 16px;
                        overflow: hidden;
            
                        p {
                          padding: 10px 15px;
                        }
            
                        .widget-drop-inner {
                          display: flex;
                          align-items: center;
                          padding: 10px 15px;
                          background: $gray150;
            
                          img {
                            height: 10px;
                            margin-right: 5px;
                          }
            
                          span {
                            position: relative;
            
                            &::after {
                              content: '';
                              position: absolute;
                              top: 8px;
                              border-left: 3px solid transparent;
                              border-right: 3px solid transparent;
                              border-top: 3px solid $black;
                              right: -10px;
                            }
                          }
                        }
                      }
            
                      .widget-input {
                        border: 1px solid $gray150;
                        padding: 1px 15px;
                        font-weight: 300;
                        border: 1px solid #d3d3d3;
                        margin-top: 10px;
                        line-height: 29px;
                        height: 33px;
                        border-radius: 5px;
                        text-align: left;
                        display: inline-block;
                        width: 100%;
                      }
            
                      .icon-class {
                        color: #636b6f;
                        font-size: 20px;
                        vertical-align: middle;
                        padding-right: 10px;
                        margin-bottom: 2px;
                      }
            
                      >label {
                        display: block;
                        margin: 10px 0;
                      }
            
                      .ui.selection.dropdown {
                        width: 100%;
                      }
                    }
            
                    .call-me-latter {
                      display: flex;
                      justify-content: space-between;
                      margin-bottom: 10px;
            
                      >div {
                        min-width: 45%;
                      }
                    }
            
            
                  }
            
                  .custom-widget-footer {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
            
                    padding-bottom: 10px;
            
                    text-align: center;
                    font-size: 14px;
            
                    label {
                      width: 100%;
                      color: #828181;
            
                      &:last-child {
                        padding-bottom: 15px;
                      }
                    }
            
                    .lime-call-footer-text-container {
                      width: 100%;
                      float: left;
                      display: inline-block;
                      text-align: center;
                      margin: 0;
            
                      a {
                        text-decoration: none;
                        background-color: initial;
                        padding: 4px 10px;
                        font-size: 11px;
                        color: #777678;
            
                        &:hover {
                          border: 1px solid #f1f1f1;
                          border-radius: 2rem;
                          text-decoration: none;
                          background-color: #f1f1f1;
                          color: #5f5e61;
                        }
                      }
                    }
            
                    span {
                      display: block;
                      width: 100%;
                      font-weight: 300;
                      text-decoration: underline;
                    }
            
                    p {
                      display: block;
                      width: 100%;
                      font-weight: 300;
                    }
                  }
                }
            
                .modern-widget-container-main {
                  min-height: 30px;
                  width: 100%;
                  border-radius: 8px 8px;
                  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.1);
                  display: inline-block;
                  padding: 0 !important;
                  border: 0;
            
                  .hor-row {
                    width: 100%;
                    float: left;
                    display: inline-block;
                    box-sizing: border-box;
                    padding: 0;
                    margin: 0;
                    top: 0 !important;
                    position: relative !important;
                  }
            
                  .modern-widget-container {
                    background-image: linear-gradient(270deg, #0174ff, #00c5ff);
                    min-height: 50px;
                    padding: 18px 18px 60px 18px;
                    z-index: -1;
                    float: left;
                    width: 100%;
                    display: inline-block;
                    position: relative !important;
                    top: 0 !important;
                    border-radius: 8px 8px 0 0;
            
            
                    .custom-widget-body2 {
                      padding: 0 !important;
                    }
            
                    .lime-modern-componey-logo-container {
                      img {
                        max-height: 30px;
                      }
                    }
            
                    h3 {
                      color: #fff !important;
                      margin: 10px 0 10px !important;
                      display: inline-block !important;
                    }
            
                    .lime-modern-greeting-body {
                      color: #fff;
                      font-size: 12px;
                      font-weight: 600;
                    }
                  }
            
                  .lime-modern-selector-button {
                    background-color: #fff;
                    box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);
                    cursor: pointer;
                    font-size: 13px;
                    border-radius: 5px;
                    font-weight: 500;
                    padding: 30px 10px;
                    width: calc(100% - 60px);
                    margin: -40px 0 60px 30px;
                    text-align: center;
                  }
            
                  .lime-modern-powered-section {
                    font-size: 10px;
                    font-weight: 500;
                    text-align: center;
                    margin: 30px 0 0 10px;
                  }
            
                }
            
                .smart-widget-container-main {
                  float: left;
                  width: 100%;
                  top: 0 !important;
                  position: relative !important;
                  background: #fff;
                  border-radius: 0 0 8px 8px;
                  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.1);
                  padding: 10px 0 10px;
            
                  .hor-row {
                    width: 100%;
                    float: left;
                    text-align: center;
                    display: inline-block;
                    box-sizing: border-box;
                    padding: 0;
                    margin: 0;
                    top: 0 !important;
                    position: relative !important;
                  }
            
                  .info-text {
                    font-size: 12px;
                    margin: 10px 0;
                  }
            
                  .input-container-main {
                    margin: 5px 0;
            
                    input {
                      width: calc(100% - 20px);
                      border: 1px solid #ccc;
                      border-radius: .25rem;
                      padding-left: 43px;
                      outline: 0;
                      line-height: 33px;
                      max-width: 200px;
            
                      &:hover {
                        border: 1px solid #000;
                        background-color: #f8f8f8;
                      }
                    }
            
                    .submit-button {
                      padding: 3px 20px;
                      font-size: 14px;
                      border: 1px solid #16299c;
                      border-radius: 5rem;
                      background-color: #16299c;
                      color: #fff;
                      margin-bottom: 10px;
                      cursor: pointer;
                      display: inline-block;
                    }
                  }
            
                  .team-container-main {
                    margin: 10px 0;
            
                    .team-text-main {
                      color: #636b6f;
                      text-align: left;
                      padding: 0 25px;
                      margin: 10px 0;
            
                    }
            
                    .team-button-container {
                      width: calc(100% - 50px);
                      padding: 5px;
                      border: 1px solid #636b6f;
                      text-align: center;
                      cursor: pointer;
                      display: inline-block;
                      font-size: 14px;
                      color: #636b6f;
                      margin-bottom: 8px !important;
                      transition: all ease-in-out 0.3s;
            
                      &:hover {
                        background-color: #e6e6e6;
                      }
                    }
                  }
            
                  .smart-widget-footer {
                    border-top: 1px solid #dee2e6;
                    padding-top: 10px;
            
                    a {
                      text-decoration: none;
                      background-color: initial;
                      padding: 4px 10px;
                      color: #454545;
                      font-size: 11px;
                      text-decoration: none;
                      color: #777678;
            
                      img {
                        height: 11px;
                      }
            
                      &:hover {
                        border-radius: 2rem;
                        background-color: #f1f1f1;
                        color: #5f5e61;
                      }
            
                    }
                  }
            
                }
            
                .smart-widget-button-container {
                  width: 100%;
                  float: left;
                  box-sizing: border-box;
                  text-align: center;
                  margin: 10px 0;
            
                  .button-connecting {
                    color: #fff;
                    display: inline-block;
                    height: 60px;
                    cursor: pointer;
                    border-radius: 30px;
                    padding: 0 10px 0 40px;
                    background-image: linear-gradient(90deg, #37ea0a, #ea8c2a);
                    max-width: 220px;
                    line-height: 56px;
                  }
            
                  .smart-call-cut {
                    margin-top: 8px;
                    padding: 9px;
                    margin-left: 10px;
                    font-size: 16px;
                    border-radius: 50%;
                    margin-top: 12px;
                    background-color: red;
                    cursor: pointer;
                    float: right;
                  }
                }
                
            }



            #button-preview{  
              min-height: 30vh;           
              position: relative;
              width: 100%;
              // background-color: #CCD0C4;
            }


            .widget-bubble1 {   
                    position: absolute !important;
                      top: 0;
              display: flex !important;
              align-items: center !important;
              width: 100% !important;
              padding: 0 !important;
            }

            .widget-bubble-wrapper {
                position: relative;      
                
                .widget-bubble.bottom_right {
                  top: auto !important;
                  bottom: calc( -30vh );
                  left: 18vw;          
                  .bubble-sec {
                    order: 2;
                  }
          
                  .bubble-text {
                    order: 1;
                  }
                }

                .widget-bubble.bottom_left {
            bottom: 0;
                  left: 0;
                }


                .widget-bubble.top_right {
                  top: 0 !important;
                  left: 18vw;
          
                  .bubble-sec {
                    order: 2;
                  }
          
                  .bubble-text {
                    order: 1;
                  }
                }
                .widget-bubble.top_left {
                  top: 0 !important;
                  left: 0;
                }          
                .template-2-button-container{       
                  position: absolute;        
                  background: #000;
                  width: max-content;
                  color: #fff;
                  top: calc(18vh - 83px);
                  transform: rotate( 270deg );
                   -webkit-transform-origin: 22px center;     
                   transform-origin: 22px center;         
                  box-shadow: 0 0 10px 0 #323201;
                  border-radius: 0 0 6px 6px;
                  padding: 9px 18px;
                  font-size: 18px;
                  font-family: Arial;
                }
                .template-2-button-right-side-container{
                  left:100%;
                  border-radius: 6px 6px 0 0;     
                  transform: rotate( 90deg );       
                  -webkit-transform-origin: 0 0;     
                  transform-origin: 0 0;   
                  white-space: nowrap; 
                     
                }
          
                .upload-custom-icon {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-bottom: 35px;
                  width: 90%;
          
                  .dzu-dropzone {
                    width: 50%;
                    margin-right: 0;
                    overflow: hidden;
                  }
                }
          
                .template-Select {
                  .ui.selection.dropdown {
                    padding: 10px;
          
                    i {
                      padding: 10px;
                    }
                  }
          
                  p {
                    margin-bottom: 0;
                    font-family: 'Gibson Regular';
                    // font-size: 16px;
                    font-size: 20px;
                    font-weight: 500;
                  }
          
                  margin-bottom: 35px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
          
                  .ui.selection.dropdown {
                    width: 50%;
                  }
                }
          
                .customize-grp-btn-wrap {
                  .groupbtn-holder {
                    flex-wrap: wrap;
          
                    .groupbtn-text {
                      margin-bottom: 20px;
                    }
          
                    >div {
                      flex: 0 0 100%;
                      max-width: 100%;
                    }
                  }
                }
          
                .widget-bubble {
                  position: absolute;
                  top: 250px;
                  display: flex;
                  align-items: center;
                  width: 100%;
          
                  .bubble-sec {
                    background: #0080ff;
                    height: 60px;
                    width: 60px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 0 0 auto;
                  }
          
                  .square , .btnRight{
                    border-radius: 0% !important;
                  }
          
                  .bubble-text {
                    background-color: #2b2b2c;
                    border-radius: 6px;
                    font-size: 13px;
                    color: #fff;
                    margin-right: 10px;
                    margin-left: 10px;
                    padding: 6px;
                    word-break: break-all;
                  }
                }
              }
          
              // .widget-bubble1 {
              //   position: absolute;
              //   top: 120px;
              //   left: 0;               
              // }

       }
   }

}
.greeting-text {
      background-color: rgb(255, 255, 255);
      border-radius: 6px;
      font-size: 13px;
      color: #000000;
      margin-right: 10px;
      margin-left: 10px;
      padding: 10px;
      word-break: break-all;
      box-shadow: 0 1px 10px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      max-width: 150px;
      position: absolute;

    }