.innertab-wrapper {
  display: flex;
  padding: 0;
}

.tab-wrapper {
  // padding: 0 68px 30px 366px;
  // margin-top: 47px;
  // width: 100%;
  padding: 0 50px;
  // width: calc(100% - 225px);
  width: 84.5%;
  margin-left: auto;

  // background-color: $white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  // border-left: 1px solid rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.innertab-item {
  position: relative;

  margin-left: 5px;

  cursor: pointer;

  &:hover div:before {
    width: 100%;
  }

  div {
    display: flex;
    justify-content: center;
    padding: 12px 20px;

    font-family: $gibson;
    font-size: 20px;
    font-weight: 300;

    transition: all .3s ease-in-out;


    &:before {
      content: "";

      position: absolute;
      top: 0;
      left: 0;

      width: 0;
      height: 6px;

      background-color: $link-color;

      transition: all .3s ease-in-out;
    }
  }

  .active {
    text-shadow: -.06ex 0 black, .06ex 0 black;

    @include on-event {
      color: $black;
    }

    // border-top: 6px solid $link-color;

    &:before {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  // .tab-wrapper {
  //   padding: 0 68px 30px 272px;
  // }

  // .innertab-item div {
  //   font-size: 16px;
  // }
}
@media only screen and (max-width: 767px) {
.innertab-item {
  &:hover div:before {
    width: 2%;
    height: 100%;
  }
  .active {
    &:before {
      width: 2%;
      height: 100%;
    }
  }
}
}