.shareable_link_wrapper {
  .full-height {
    height: 100vh;
  }

  .flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .position-ref {
    position: relative;
  }

  .top-right {
    position: absolute;
    right: 10px;
    top: 18px;
  }

  .content {
    text-align: center;
  }

  .title {
    font-size: 84px;
  }

  .links > a {
    color: #636b6f;
    padding: 0 25px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
  }

  .m-b-md {
    margin-bottom: 30px;
  }

  .alert alert-danger {
    color: #161e48;
    font-size: large;
    font-weight: bold;
  }
}
