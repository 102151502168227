.manage-teammates-wrapper {
  margin-top: 46px;
  .input-search-wrapper {
    .sub-text.default-text.input-description {
      display: flex;
      justify-content: space-between;
    }
  }
  .manage-teammates-table {
    .common-table-wrapper {
      .ui.single.line.table {
        th {
          font-size: 14px;
          font-weight: 600;
        }
        td {
          font-size: 14px;
        }
      }
    }
  }

  .bold-text {
    margin-bottom: 16px;
  }

  .sub-description {
    margin-bottom: 58px;

    font-family: $lato;
    font-weight: 500;
  }

  .textarea-subtext {
    margin-top: 32px;

    font-family: $lato;
    font-weight: 500;

    span {
      padding: 0 2px;

      color: $black;

      background: $gray100;
    }
  }

  .textarea-wrapper {
    .textarea-description {
      margin-bottom: 30px;
      width: 796px;

      font-family: $gibson;
      font-weight: 500;
    }

    .textarea-descripition-text {
      font-family: $gibson;
      font-weight: 500;
      line-height: 18px;
    }

    .textarea-descripition-title {
      margin-bottom: 16px;
    }

    .textarea-style {
      width: 770px;
      height: 120px;

      border: 1px solid $gray100;
    }
  }

  .input-search-holder {
    display: flex;
    margin-bottom: 25px;

    button {
      padding: 10px 33px 10px 20px;

      font-size: 19px;
      font-weight: 600;

      .fa-plus {
        margin-right: 20px;
      }

      .plus {
        font-size: 20px;
        color: $white;
      }
    }
  }

  .input-search-wrapper {
    margin-top: 48px;

    .input-description {
      margin-bottom: 10px;

      color: $gray300;
    }
  }

  .input-search-box {
    flex-basis: 52%;
    margin-right: 5px;

    .input {
      width: 100%;

      input {
        width: 100%;
      }
    }
  }

  .common-table-wrapper {
    width: 1409px;
    max-width: 100%;
    overflow: auto;
  }

  table.ui.single.line.table {
    border: none;
    box-shadow: 0 0 14px 4px #f5f5f5;
  }

  thead.top-table-header tr th {
    padding-left: 30px;

    font-weight: 500;

    background: $gray100;
  }

  .ui.table td {
    padding-left: 25px;
  }

  tr.table-content-row {
    background: $hover-row;

    td:first-child {
      font-weight: 600;
      color: $link-color;
    }
    .toggle-group {
      margin-bottom: 15px;
      padding-top: 13px;
    }
  }
}

.confirmBox_wrapper .actions button.ui.primary.button {
  width: 29% !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .manage-teammates-wrapper {
    .subtext {
      font-size: 14px;
    }

    .input-search-holder button {
      font-size: 16px;
    }
  }
}

.team-update-select .css-yk16xz-control{
  color: black !important;
  background-color: #f4f4f4;
}
