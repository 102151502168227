.channle_dropdwon {
  margin: 70px 20px 0px 92px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15), 0 -1px 0px rgba(0, 0, 0, 0.02);
  padding: 38px 20px 38px 20px;
}

@media (min-width: 320px) and (max-width: 575px) {
  .slack_wpapper {
    .title-container.header_fixed {
      .title-wrapper {
        .title-two {
          font-size: 15px;
        }
      }
    }
    .channle_dropdwon {
      padding: 18px 20px 20px 20px;
      margin: 70px 20px 0px 62px;
      .slack_channle_dropdwon {
        padding: 10px 0px;
        .ui.selection.dropdown {
          height: auto;
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .slack_wpapper {
    .title-container.header_fixed {
      .title-wrapper {
        .title-two {
          font-size: 15px;
        }
      }
    }
    .channle_dropdwon {
      padding: 18px 20px 20px 20px;
      margin: 70px 20px 0px 92px;
      .slack_channle_dropdwon {
        padding: 10px 0px;
        .ui.selection.dropdown {
          height: auto;
        }
      }
    }
  }
}
