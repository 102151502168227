.usage-wrapper {
  margin-top: 50px;
  max-width: 800px;
  width: 100%;
  border: 1px solid #d3d3d3;
  padding: 20px;
  background: $white;
  border-radius: 10px;

  h4 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .usage-details-wrapper {
    .usage-heading-wrapper{
      .usage-details{
        span{
          padding: 6px;
          background-color: #f6f6f6;
          border: 1px solid #bbbbbb;
          border-radius: 4px;
          margin-left: 40px;
        }
      }
    }
    .usage-details {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 10px 0;
      width: 45%;
      height: 100%;
      margin-bottom: 10px;
      justify-content: start;
      padding-left: 77px;
      > div p{
        font-weight: 500;
        color: #909090;
        text-align: left;
        padding-right: 16px;
      }

      p, label, span {
        font-weight: 300;
        display: block;
        font-size: 16px;
        margin-bottom: 0;
      }

      span {
        font-weight: 500;
        color: #000000;
      }
      
      label{
        font-weight: 500;
        color: #000000;
      }

      div:first-child {
        flex: 0 0 40%;
        max-width: 40%;
      }
      div:last-child {
        flex: 0 0 60%;
        max-width: 60%;
      }
    }
  }

  .progressbar {
    background: $gray100;
    border-radius: 5px;
    overflow: hidden;
    margin: 10px 0;

    &-inner {
      background: $green;
      height: 10px;
    }
  }

  // thead.top-table-header tr th {
  //   padding-left: 30px;

  //   font-weight: 500;

  //   background: #f4f4f4;
  // }

  // .ui.table tr {
  //   &:hover {
  //     background: $hover-row;

  //     cursor: pointer;
  //     transition: all .2s ease-in-out;
  //   }

  //   td {
  //     padding-left: 30px;




  //     &:last-child {
  //       color: #f7a646;
  //     }
  //   }
  // }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  // .usage-wrapper .ui.table td {
  //   font-size: 16px;
  // }
}
