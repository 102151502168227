#signup-otp {    
    background-color: #fff;
    min-width: 100vw;
    min-height: 100vh;

   

    div.logo-container{
        padding: 16px 60px !important;
        .logo{
             img{
                height: 36px;                
             }
        }
    }
    div.fullwidth-container{       
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 85vh;

        #confirm_email_form_body{
            //display: flex;
            //flex-direction: row;  
            width: 40vw;              
        }

        .flex-table{
            display: flex; 
            flex-direction: row;
            justify-content: start;
            align-items: center;
            margin: 0 !important;padding: 0;
            width:60%;
        }

        h1{
            font-size: 2.625rem;
            line-height: 3rem;
            font-weight: 300;
            margin-bottom: 2rem;
             letter-spacing: -1px;
             font-family: Slack-Lato,appleLogo,sans-serif;
        }

        p.desc{
            font-size: 1.25rem;
            color: #9e9ea6;
            margin-bottom: 2rem;        
        }

        #confirmButton{
            background: #2ab27b;
            color: #fff;
            border: 0;
            margin: auto;
            border-radius: 4px;
            padding: 10px 24px;
            cursor: pointer;
            height: 53px;
            display: inline-block;
            font-size: 1.0625rem;
            font-family: Slack-Lato,sans-serif;
            font-weight: 700;
            background-color: #42c299;
            color: #fff!important;
            text-shadow: none;
            position: relative;
            display: block;
            margin: auto;
            margin-top: 16px;
            margin-bottom: 16px;
        }

        #resendButton{
            color: #2ab27b;
            background-color: #fff;
            border: 0;
            margin: auto;
            border-radius: 4px;
            padding: 10px 0;
            cursor: pointer;
            height: 53px;
            display: inline-block;
            font-size: 1.0625rem;
            font-family: Slack-Lato,sans-serif;
            font-weight: 700;            
           
            text-shadow: none;
            position: relative;
            display: block;
            margin: auto;
            margin-top: 16px;
            margin-bottom: 16px;
        }
        
        .confirmation_code{
            display: flex;
            flex-direction: row;
            margin-bottom: 1rem !important;
            border-spacing: 0;
            .confirmation_code_group{
                display: block;               
                .split_input_item:first-child {
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }
                .split_input_item {
                    border: none !important;
                    display: table-cell !important;
                    position: relative;

                    input[type=text]{                        
                            font-size: 1.25rem;
                            line-height: normal;
                            padding: .75rem;
                            border: 1px solid #C5C5C5;
                            border-radius: .25rem;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                            outline: 0;
                            color: #555459;
                            width: 100%;
                            max-width: 100%;
                            font-family: Slack-Lato,appleLogo,sans-serif;
                            margin: 0;

                            font-variant-ligatures: none;
                            -webkit-transition: box-shadow 70ms ease-out,border-color 70ms ease-out;
                            -moz-transition: box-shadow 70ms ease-out,border-color 70ms ease-out;
                            transition: box-shadow 70ms ease-out,border-color 70ms ease-out;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            box-shadow: none;
                            height: auto;
                        
                    }
                }

                .confirmation_code_checker{
                    margin: 24px auto;
                }
            }
        }
    }

}   

@media screen and (max-width: 600px){
    #signup-otp div.fullwidth-container #confirm_email_form_body {
        width: 90vw;
    }
}

