.ui.page.modals.dimmer.transition.visible.active {
  .LeadPageModal {
    overflow: visible;

    .content {
      .ui.selection.dropdown {
        height: auto;
      }
    }

    button {
      float: right;
      margin-bottom: 13px;
    }

    .visible.menu.transition {
      .item {
        display: flex;
        align-items: center;
        padding-left: 20px !important;
      }
    }

    a.ui.label {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;

      img.ui.image {
        height: 100% !important;
        margin-right: 10px;
        width: 16px !important;
      }
    }
  }

  .sendMessageModal {
    .content {
      .input-wrapper:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
      }

      .input-wrapper:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;

        input {
          background-color: #e9ecef;
        }
      }
    }
  }
}

.titleCall {
  display: flex;
  align-items: center;

  img {
    width: 15px;
  }

  h5 {
    margin: 0px 35px 0px 35px;

    @media(max-width:424px) {
      margin: 0px 20px 0px 20px;
      font-size: 17px;
    }

  }
}

.ui.multiple.dropdown>.label {
  background-color: #2296f3;
  color: #f0f0f0;
  font-weight: 500;
  font-size: 12px !important;
}

.ui.multiple.dropdown .dropdown.icon {
  margin-top: 6px;
}




.ui.inverted.dimmer {
  >.ui.modal.email-modal {
    top: 212px;
    left: 512px;

    width: 808px;
    max-width: 100%;

    border: 1px solid $link-color;
    border-top-left-radius: 0;

    &:before {
      content: '';

      position: absolute;
      top: 0;
      left: -11px;
      z-index: 2;

      width: 0;
      height: 0;

      border-top: 23px solid transparent;
      border-right: 19px solid $white;
      border-bottom: 0 solid transparent;

      transform: rotate(-89deg) translate(2px, -9px);
    }

    &:after {
      content: '';

      position: absolute;
      top: 0;
      left: -11px;
      z-index: 1;

      width: 0;
      height: 0;

      border-top: 24px solid transparent;
      border-right: 20px solid $link-color;
      border-bottom: 0 solid transparent;

      transform: rotate(-89deg) translate(3px, -12px);
    }

    .actions {
      position: relative;
      top: -6px;

      display: flex;
      justify-content: flex-end;

      >.button {
        padding: 7px 22px;
        margin-left: 24px;

        text-transform: uppercase;
      }
    }

    .ui.checkbox label {
      padding-left: 24px;

      font-size: 14.4px;

      &:before {
        border-color: $link-color;
      }
    }
  }

  .ui.modal.email-modal>.content {
    padding: 14px 24px;

    background: #f0fafc;

    >.description {
      font-size: 16px;
      line-height: 30px;

      span {
        margin-right: 8px;

        font-size: 16px;
        color: $link-color;
        text-transform: uppercase;
      }
    }
  }
}

.call-dashboard {

  .info-item {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .checkWrap {
      width: 20px;
      height: 19px;
      background-color: #000 !important;
      background: #13ca6e;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }

    .info-item-inner {
      display: flex;

      .checkWrap {
        width: 20px;
        height: 19px;
        background-color: #000 !important;
        background: #13ca6e;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
      }
    }

    img {
      width: 12px;
      height: 10px;
    }
  }

  .content {
    padding: 53px 420px 30px 108px;
    min-height: 100vh;

    background-color: $gray100;

    span {
      color: $link-color;
    }
  }

  .profile {
    padding: 0 2px;
    margin: 25px 0;
    width: 1380px;
    max-width: 100%;

    box-shadow: 1px 1px 14px 4px rgba(0, 0, 0, 0.3);

    &-upper-details {
      position: relative;

      display: flex;
      flex-wrap: wrap;
      padding: 25px 31px 25px 42px;

      background-color: $white;
    }

    &-image {
      display: flex;
      flex-wrap: wrap;
      width: 410px;
    }

    &-image-holder {
      display: flex;
      align-items: center;

      .image {
        width: 100%;
        height: 122px;
      }
    }

    &-id {
      padding: 14px 33px;
    }

    &-id-number {
      margin-bottom: 6px;

      font-size: 20px;
      font-weight: 600;
    }

    &-buttons {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      width: 168px;
      max-width: 100%;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 47%;

        background-color: transparent;
        border-radius: 4px;
      }

      .btn-green-call {
        border: 1px solid $green;

        img:nth-child(2) {
          display: none;
        }

        &:hover {
          background: green;
          border: 1px solid $green;

          cursor: pointer;
          transition: all 0.2s ease-in-out;

          img:first-child {
            display: none;
          }

          img:nth-child(2) {
            display: block;
          }
        }
      }

      .btn-blue-email {
        position: relative;
        z-index: 1;

        border: 1px solid $link-color;

        &.active {
          z-index: 3;
        }

        img:nth-child(2) {
          display: none;
        }

        &:hover {
          background: $link-color;
          border: 1px solid $link-color;

          cursor: pointer;
          transition: all 0.2s ease-in-out;

          img:first-child {
            display: none;
          }

          img:nth-child(2) {
            display: block;
          }
        }
      }
    }

    &-id-text {
      margin-bottom: 6px;

      font-size: 21px;

      span {
        margin-left: 19px;

        color: $link-color;
      }
    }

    &-country {
      display: flex;

      span {
        margin-left: 25px;

        font-size: 21px;
        color: $link-color;
      }
    }

    &-personal-info {
      padding: 19px 32px;
      width: 406px;

      font-size: 20px;
    }

    &-info-text {
      padding-left: 61px;
      margin-bottom: 7px;

      span {
        margin-left: 16px;

        color: $link-color;
      }

      a {
        margin-left: 16px;

        color: $link-color;
      }
    }

    &-info {

      &-name,
      &-email {
        position: relative;

        &:before {
          position: absolute;
          top: 50%;
          left: 0;

          transform: translateY(-50%);
        }
      }

      &-name {
        &:before {
          content: 'Name:';
        }
      }

      &-email {
        &:before {
          content: 'Email:';
        }
      }
    }

    &-company-info {
      padding: 21px 13px 21px 0;
      width: 400px;

      font-size: 16px;
    }

    &-company-text {
      position: relative;

      padding-left: 135px;
      margin-bottom: 4px;

      &:before {
        position: absolute;
        top: 50%;
        left: 0;

        transform: translateY(-50%);
      }

      a {
        color: $link-color;
      }
    }

    &-company-ip {
      &:before {
        content: 'IP Address:';
      }
    }

    &-company-phone {
      &:before {
        content: 'Company Phone:';
      }
    }

    &-company-domain {
      &:before {
        content: 'Company Domain:';
      }
    }

    &-company-email {
      &:before {
        content: 'Company Email:';
      }
    }

    &-social-media {
      position: absolute;
      top: 25px;
      right: 25px;

      display: flex;
      justify-content: space-between;
      width: 120px;

      a {
        font-size: 32px;
        color: $gray300;

        &:hover {
          color: $link-color;

          cursor: pointer;
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }

  .record {
    padding: 25px 2px;
    margin-bottom: 5px;
    width: 1380px;
    max-width: 100%;

    background-color: $white;

    &-heading {
      padding: 3px 44px;
      margin-bottom: 49px;
    }

    &-title {
      margin-bottom: 5px;

      font-size: 20px;
    }

    &-subtitle {
      font-size: 20px;
      color: $link-color;
    }

    &-image-holder {
      margin-bottom: 63px;

      img {
        margin: 0 auto;
      }
    }

    &-control {
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        position: relative;

        width: 32px;
        height: 32px;

        background-color: transparent;
        border: 0;

        @include on-event {
          img:first-child {
            // display: none;
            opacity: 0;
          }

          img:nth-child(2) {
            // display: block;
            // width: 32px;
            opacity: 1;
          }
        }

        img {
          position: absolute;
          top: 50%;
          left: 50%;

          transition: all 0.3s ease-in-out;
          transform: translate(-50%, -50%);
        }

        img:first-child {
          opacity: 1;
          // display: block;
        }

        img:nth-child(2) {
          opacity: 0;
          // display: none;
        }
      }
    }

    &-play-pause {
      display: flex;
      align-items: center;
      margin: 0 20px;

      button {
        &:first-child {
          margin-right: 10px;
        }
      }
    }

    &-details {
      display: flex;
      flex-wrap: wrap;
      padding: 0 43px;

      font-size: 20px;
    }

    &-details-text {
      position: relative;

      margin-bottom: 7px;
      width: 415px;

      span {
        margin-left: 15px;

        color: $link-color;
      }
    }

    &-details-duration {
      &:before {
        content: 'Duration:';
      }
    }

    &-details-keywords {
      &:before {
        content: 'Keywords Spotted:';
      }
    }

    &-details-callstatus {
      &:before {
        content: 'Call Status:';
      }
    }

    &-details-timecall {
      &:before {
        content: 'Time of call:';
      }
    }

    &-details-agent {
      &:before {
        content: 'Agent Attended:';
      }
    }

    &-details-source {
      &:before {
        content: 'Call Source:';
      }
    }

    &-details-tracking {
      &:before {
        content: 'Tracking Number:';
      }
    }

    &-details-traffic {
      &:before {
        content: 'Traffic Source:';
      }
    }

    &-score {
      display: flex;

      &.lead {
        &:before {
          content: 'Lead Score:';
        }
      }

      &.call {
        &:before {
          content: 'Call Score:';
        }
      }

      span {
        display: flex;
        align-items: center;
        padding: 0 8px;
        margin-left: 24px;

        border: 1px solid $gray100;
        border-radius: 10px;

        img {
          margin: 0 2.4px;
          width: 12px;
        }
      }
    }

    &-tags {
      &:before {
        content: 'Tags:';
      }

      span {
        span {
          margin-left: 4px;

          font-size: 12px;

          border: 1px solid $gray100;
          border-radius: 10px;

          &:first-child {
            padding: 7px 14px;
          }

          &:nth-child(2) {
            padding: 7px 34px;
          }

          &:last-child {
            padding: 7px 20px;
          }
        }
      }
    }
  }

  .textarea {
    display: flex;
    justify-content: space-between;
    width: 1380px;
    max-width: 100%;

    &-notes,
    &-feedback {
      position: relative;

      width: 49.8%;

      &:before {
        position: absolute;
        top: 0;
        left: 30px;

        width: 50px;
        height: 50px;
      }

      &:after {
        position: absolute;
        top: 10px;
        left: 79px;

        font-size: 20px;
      }

      textarea {
        padding: 11px 15px;
        width: 100%;
        min-height: 125px;

        font-size: 20px;

        border: 0;

        resize: none;
      }
    }

    &-notes {
      &:before {
        content: '';

        background-image: url(../assets/images/call-notes.png);
        background-position: center;
        background-repeat: no-repeat;
      }

      &:after {
        content: 'Notes:';
      }

      textarea {
        padding: 11px 15px 11px 146px;

        resize: none;
      }
    }

    &-feedback {
      &:before {
        content: '';

        background-image: url(../assets/images/call-feedback.png);
        background-position: center;
        background-repeat: no-repeat;
      }

      &:after {
        content: 'Caller Feedback:';
      }

      textarea {
        padding: 11px 15px 11px 230px;

        resize: none;
      }
    }
  }

  .message {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2;

    padding-top: 52px;
    width: 400px;
    max-width: 100%;
    min-height: 100vh;

    background-color: $white;
    border-left: 1px solid $gray150;

    &-heading {
      display: flex;
      align-content: center;

      border-bottom: 1px solid $gray150;

      button {
        padding: 12px 22px;

        font-size: 20px;
        color: $link-color;

        background-color: transparent;
        border: 0;
      }
    }

    &-title {
      display: flex;
      align-items: center;
      margin-left: 20px;

      font-size: 16px;
      color: $gray150;
    }

    &-id {
      margin-left: 6px;
    }

    &-content {
      padding: 28px 35px;
    }

    &-text {
      position: relative;

      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }

    &-text-holder {
      padding: 14px 36px;
      margin: 0 auto;
      width: 300px;
      max-width: 100%;

      font-size: 11px;

      background-color: $gray100;
      border-radius: 45px;
    }

    &-receive {
      .message-image {
        left: 0;
      }
    }

    &-send {
      margin-right: 15px;
      .message-text-holder {
        color: $white;

        background-color: $link-color;
      }

      .message-image {
        right: 0;

        overflow: hidden;

        background-color: $link-color;
      }
    }

    &-image {
      position: absolute;
      bottom: 0;

      width: 16px;
      height: 16px;

      background-color: $gray100;
      border-radius: 100%;
    }

    &-footer {
      position: absolute;
      bottom: 0;

      padding: 42px 5px 50px 24px;
      width: 100%;

      textarea {
        padding: 12px;
        width: 100%;
        height: 108px;

        font-size: 15px;

        border: 1px solid $gray150;

        resize: none;

        &::placeholder {
          color: $gray150;
        }
      }
    }

    &-settings {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2px;

      button {
        font-size: 15px;
        color: $link-color;

        background: transparent;
        border: 0;
      }
    }

    &-quick-text {
      display: flex;
      align-items: center;
      margin: 0;
      margin-left: 12px;

      font-size: 16px;
      color: $link-color;

      &:after {
        content: url('../assets/images/chevron-blue.png');

        margin-left: 5px;
      }
    }

    &-save {
      display: flex;
      justify-content: space-between;

      .holder-checkbox {
        display: flex;
        align-items: center;
      }

      .ui.checkbox label {
        padding-left: 28px;

        font-size: 12px;
      }

      .btn-green {
        padding: 11px 27px;

        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;

        border: 1px solid $green;
      }
    }
  }
}

@media screen and (max-width: 1440px) and (min-width: 1200px) {
  .ui.inverted.dimmer {
    justify-content: center;
    overflow: scroll;

    >.ui.modal {
      top: initial;
      left: initial;
    }

    >.ui.modal.email-modal {
      top: 190px;
      left: 445px;

      width: 649px;
    }
  }

  .call-dashboard {
    .content {
      padding-right: 330px;
      padding-left: 75px;
    }

    .profile {
      &-upper-details {
        padding: 10px 15px;
      }

      &-image {
        width: 100%;
      }

      &-personal-info {
        padding: 19px 0;
        width: 50%;

        font-size: 16px;
      }

      &-company-info {
        padding: 21px 0;
        width: 50%;
      }

      &-social-media {
        position: absolute;
        top: 25px;
        right: 25px;

        padding: 0;
        width: 100px;
      }

      &-id-number {
        font-size: 16px;
      }

      &-id-text {
        font-size: 16px;
      }

      &-country {
        align-items: center;

        img {
          width: 20px;
          height: 15px;
        }

        span {
          font-size: 16px;
        }
      }
    }

    .record {

      &-heading,
      &-details {
        padding: 0 20px;
      }

      &-details-text {
        padding-left: 135px;
        width: 100%;

        &:before {
          position: absolute;
          top: 50%;
          left: 0;

          transform: translateY(-50%);
        }

        span {
          margin: 0;
        }
      }

      &-title {
        font-size: 16px;
      }

      &-subtitle {
        font-size: 16px;
      }

      &-details {
        font-size: 16px;
      }

      &-score {
        span {
          padding: 5px;
        }
      }

      &-tags {
        span {
          span {
            &:first-of-type {
              margin: 0;
            }
          }
        }
      }
    }

    .message {
      padding-top: 50px;
      width: 300px;

      &-content {
        padding: 25px 20px;
      }

      &-text-holder {
        padding: 15px 25px;
        width: 245px;
      }

      &-footer {
        padding: 20px;
      }
    }

    .textarea {

      &-notes,
      &-feedback {
        &:after {
          font-size: 16px;
        }

        &:before {
          height: 40px;

          background-size: 20px;
        }
      }
    }
  }
}

.Lead_wrapper {
  border-color: transparent !important;
  box-shadow: none !important;
  background: transparent !important;

  .title {
    border-radius: 2px 2px 0 0;
    background-color: #f9fafc;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15), 0 -1px 0px rgba(0, 0, 0, 0.02);
    border-color: transparent !important;
    color: #000 !important;
    font-weight: 700 !important;
    margin-bottom: 15px !important;
    font-size: 16px !important;
    text-transform: capitalize !important;
  }

  .title.active {
    background-color: #f9fafc !important;
    border-color: transparent !important;
    margin-bottom: 0 !important;
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.15), 0 0px 0px rgba(0, 0, 0, 0.02);
  }

  .title:hover {
    background-color: #f9fafc !important;
  }

  .title::before {
    top: 50%;
    color: rgba(70, 90, 110, 0.85);
    font-size: 14px;
  }

  .content {
    background: #fff;
    padding: 15px !important;
    min-height: auto !important;
    margin-bottom: 15px !important;
  }

  .content.active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15), 0 -1px 0px rgba(0, 0, 0, 0.02);
  }

  .lead-data-icon {
    width: 17px;
    margin-right: 10px;
  }

  .row {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
  }

  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .detail_text {
    color: #000 !important;
    font-weight: 300 !important;
    font-size: 14px !important;

    .lead-details-star-rating {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15), 0 -1px 0px rgba(0, 0, 0, 0.02);
      border-radius: 2px;
      font-size: 1.47em;
      text-align: center;
      padding: 5px 0px;

      .fa-star {
        font-size: 14px;
        color: #dee2e6 !important;
      }
    }
  }

  .lead_title {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1200px) {
  .call_detail_section .call_information_wrapper {
    padding-right: 180px;
  }
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
  .call_detail_section {
    width: calc(100% - 30px) !important;
  }

  .call_detail_section .call_information_wrapper {
    padding: 53px 310px 30px 28px;

    .message {
      width: 290px;
    }
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .call_detail_section {
    width: calc(100% - 30px) !important;
  }

  .call_detail_section .call_information_wrapper {
    padding: 53px 310px 30px 28px;

    .message {
      width: 290px;
    }

    .profile {
      .profile-upper-details {
        padding: 25px 15px 25px 15px;

        .profile-image {
          flex-wrap: nowrap;

          .profile-id {
            p {
              font-size: 16px;
            }

            p.profile-country {
              display: flex;
              align-items: center;

              span {
                font-size: 16px;
              }
            }
          }

          .profile-image-holder {
            img {
              height: auto;
            }
          }
        }
      }
    }

    .record {
      .record-details {
        padding: 0 15px;

        p.record-details-text {
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) and (min-width: 320px) {
  .call_detail_section {
    width: calc(100% - 0px) !important;
  }

  .call_detail_section .content.call_information_wrapper {
    padding: 15px 15px 30px 15px;

    .message {
      width: 290px;
      transform: translateX(80%);
      background: transparent;
      border: none;
      margin-top: 8px;
      transition: all 0.3s;

      .message-heading {
        background-color: #fff;
      }

      .message-content {
        visibility: hidden;
      }

      .message-footer {
        visibility: hidden;
        padding: 42px 24px 50px 24px;
      }
    }

    .message.display__message {
      transform: translateX(0%);
      background: #fff;
      border-left: 1px solid #d3d3d3;

      .message-heading {
        button {
          i.fas::before {
            content: '\f061';
          }
        }
      }

      .message-content {
        visibility: visible;
      }

      .message-footer {
        visibility: visible;
      }
    }

    .profile {
      .profile-upper-details {
        padding: 25px 15px 25px 15px;

        .profile-image {
          flex-wrap: nowrap;

          .profile-id {
            p {
              font-size: 16px;
            }

            p.profile-country {
              display: flex;
              align-items: center;

              span {
                font-size: 16px;
              }
            }
          }

          .profile-image-holder {
            img {
              height: auto;
            }
          }
        }
      }
    }

    .record {
      .record-details {
        padding: 0 15px;

        p.record-details-text {
          font-size: 16px;
        }
      }
    }
  }
}

// lead page
.lead_page {
  flex: 1;

  padding: 32px 32px 32px 170px;
  background-color: #f3f6f9;

  .live_call {
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.149);
    border-radius: 10px;

    p.live_call_icon {
      img {
        padding-right: 5px;
      }

      margin-bottom: 0;
      color: #fff;
      background: #22b573;
      padding: 24px 0px;
      font-size: 16px;
      border-top-left-radius: 9px;
      border-bottom-left-radius: 9px;
      font-weight: 500;
      min-width: 136px;
      text-align: center;
      height: 71px;
      font-weight: bold;
    }

    .live_call_date {
      justify-content: space-between;
      font-size: 14px;
      color: #383838;
      background: #fff;
      width: 100%;
      padding: 0px 15px 0px 20px;
      border-top-right-radius: 9px;
      border-bottom-right-radius: 9px;
      height: 71px;
      display: flex;
      align-items: center;

      b {
        margin-right: 5px;
      }
    }
  }

  .leadInfo {
    .Lead_wrapper {
      .detail_text {
        text-transform: capitalize;
      }

      display: block;

      .active.title {
        border-radius: 9px 9px 0px 0px;
      }

      .content.active {
        border-radius: 0px 0px 9px 9px;
      }

      .title {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.149) !important;
        border-radius: 9px;
      }
    }
  }

  .visitData {
    .Lead_wrapper {
      display: block;

      .detail_text {
        text-transform: capitalize;
      }

      .active.title {
        border-radius: 9px 9px 0px 0px;
      }

      .content.active {
        border-radius: 0px 0px 9px 9px;
      }

      .title {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.149) !important;
        border-radius: 9px;
      }
    }
  }

  .CallLogs {
    .Lead_wrapper {
      display: block;

      .detail_text {
        text-transform: capitalize;
      }

      .active.title {
        border-radius: 9px 9px 0px 0px;
      }

      .content.active {
        border-radius: 0px 0px 9px 9px;
      }

      .title {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.149) !important;
        border-radius: 9px;
      }
    }
  }
  .insights
  .Calldata {
    margin-top: 15px;

    .Lead_wrapper {
      .detail_text {
        text-transform: capitalize;
      }

      display: block;

      .active.title {
        border-radius: 9px 9px 0px 0px;
      }

      .content.active {
        border-radius: 0px 0px 9px 9px;
      }

      .title {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.149) !important;
        border-radius: 9px;
      }
    }
  }

  .dispaly_profile {
    margin-top: 15px;
    border-radius: 9px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.149);

    .profile_boxes {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #f3f6f9;
      padding: 15px;
      border-bottom: 1px solid #edf0f4;
      border-top-left-radius: 9px;
      border-top-right-radius: 9px;

      .profile_left {
        display: flex;
        align-items: center;

        .profile_images {
          display: block;
          height: 50px;
          width: 50px;
          border-radius: 9px;

          img {
            max-width: 100%;
            min-width: 100%;
          }
        }

        .profile_detail {
          padding-left: 20px;

          p {
            margin-bottom: 0;
            font-size: 18px;
            color: #283238;
          }
        }
      }

      .sql_set {
        p {
          background-color: #038bfc;
          color: #fff;
          font-size: 16px;
          padding: 4px 15px;
          border-radius: 7px;
          font-weight: 400;
          letter-spacing: 1px;
        }
      }
    }

    .dispaly_profile_detail {
      background-color: #fff !important;
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
      padding: 15px;

      .display_button {
        margin: 15px;

        .call_set {
          background: #22b573;
          text-align: center;
          padding: 10px 0px;
          border-radius: 6px;
          width: 31%;
          margin: 0px 1%;
        }

        .mail_set {
          background: #0071eb;
          text-align: center;
          padding: 10px 0px;
          border-radius: 6px;
          width: 31%;
          margin: 0px 1%;
        }

        .clock_set {
          background: #7f5eff;
          text-align: center;
          padding: 10px 0px;
          border-radius: 6px;
          width: 31%;
          margin: 0px 1%;
        }
      }

      .profile_content {
        display: flex;
        align-items: center;
        padding: 7px 0px;

        .location_text {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .location_set {
            display: flex;
            align-items: center;
          }

          .time_set {
            p {
              display: flex;
              align-items: center;

              img {
                margin-right: 10px;
              }
            }
          }
        }

        .call_icon {
          height: 20px;
          width: 20px;
          border-radius: 50%;
          margin-right: 15px;
          background: #3c7de6;
          text-align: center;
          line-height: 17px;
        }

        p {
          color: #6c8f9e;
          font-size: 14px;

          span {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .lead_deatil_side {
    .noteDataToggleStyle {
      display: flex;
      margin-top: 40px;
      box-shadow: '0px 0px 4px rgb(0 0 0 / 15%)';

      .textareaContainer {
        display: flex;
        flex-direction: column;
        width: 65%;
        background: #fff;
        padding: 13px 15px;
        border-radius: 9px;

        .buttonContainer {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .messageIcon {
        color: #578a67;
        font-size: 20px;
      }

      .noteDataTextarea {
        margin-bottom: 10px;
      }
    }

    .noteDataToggleDataStyle {
      margin-top: 10px;
      display: flex;

      .textareaContainer {
        display: flex;
        flex-direction: column;
        width: 65%;
        background: #fff;
        padding: 0 0px 0 15px;
        border-radius: 9px;

        .textareaContainerHeader {
          p:nth-child(1) {
            margin-bottom: 0;

            b {
              margin-top: 5px;
            }
          }

          p {
            display: flex;
            justify-content: space-between;

            span {
              color: rgba(255, 255, 255, 0.85);
              background-color: #53a6fa;
              top: 0;
              right: 0px;
              padding: 0.5rem;
              border-top-right-radius: 9px;
            }
          }
        }

        .buttonContainer {
          display: flex;
          align-items: center;
          margin: 10px 0;

          div:nth-child(1) {
            margin-right: 10px;
          }
        }
      }

      .messageIcon {
        color: #578a67;
        font-size: 20px;
      }
    }

    .title_wrapper {
      display: flex;

      .tags_set {
        display: flex !important;
        align-items: center !important;
        // border-color: #9a9393 !important;
        // background-color: white !important;

        .add-comment-btn {
          padding: 0px 13px 0 13px;
          font-size: initial !important;
          font-weight: 300 !important;
        }

        .divider.text {
          margin: 0px auto;
          font-size: initial !important;
          font-weight: 300 !important;
        }

        .ui.label {
          font-size: initial !important;
          font-weight: 300 !important;
        }

        margin-right: 8px;

        .Colaboration-dropdown>.label~.text {
          display: none !important;
        }

        .dropdown-wrapper {
          display: flex;
          align-items: center;
          padding: 7px 14px !important;

          a.ui.label {
            display: flex !important;
            align-items: center !important;
          }

          .divider.text {
            display: flex;
            align-items: center;
            color: #000 !important;
          }

          .menu.transition {
            width: 100%;

            .item {
              display: flex;
              align-items: center;
            }
          }
        }

        img {
          margin-right: 5px;
          width: 14px !important;
          height: 14px !important;
        }

        // border: 1px solid #dee2e6;
        // background: #f0f0f0;
        // padding: 2px 14px;
        border-radius: 4px;
        cursor: pointer;

        // .ui.multiple.dropdown.ScoreDropdown {
        //   padding: 2px 14px !important;
        // }
      }
    }
  }

  .main_wrapper {
    .status-wrapper .ui.label {
      padding: 0.8em 2em;
    }

    .good-fit-menu  {
      justify-content: flex-end;
      align-items: center;
      display: flex;

      margin-bottom: 0.8 rem;

      .ui.label {
        padding: 0.70em 1.2em;
        font-size: initial !important;
        font-weight: 300 !important;
        border: 1px solid #dee2e6;
        background: #f0f0f0;
      }

      .status-wrapper {
        align-items: center;
        margin-left: 57px;
        display: flex;

        .ui.selection.dropdown {
          margin-left: 42px;
        }
      }

      .ui.menu {
margin-top: 0;
        // margin-left: 42px;
        border-radius: 25px;
        overflow: hidden;
      }

      .ui.menu .active.item {
        // background: #f0f0f0;
        background: #0071eb;
        font-weight: 700;
      }

      a.item {
        padding: 10px 20px !important;
      }

      .ui.menu .active.item:hover,
      .ui.vertical.menu .active.item:hover {
        background-color: rgb(83, 166, 250);
      }
             &.middle-container {
              display: flex;
              align-items: center;
              .middle-content{
                &+.middle-content{
                  margin-left: 30px;
                  }
                  
                  .default.text {
                    font-weight: 600;
                  }
              }
             }
    }
   
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .lead_page {
    padding: 32px 10px 32px 59px;

    .main_wrapper {
      margin: 0 -10px;

      .title_wrapper {
        display: block;

        .tags_set {
          margin: 0px 0px 15px 0px;

          .dropdown-wrapper {
            width: 100%;
            justify-content: space-between;

            .divider.text {
              margin: 0px;
            }
          }
        }
      }
    }

    .live_call {
      p.live_call_icon {
        min-width: 56px;

        span {
          display: none;
        }
      }
    }

    .dispaly_profile {
      .profile_boxes {
        display: block;

        .profile_left {
          margin-bottom: 15px;
        }

        .sql_set {
          p {
            text-align: center;
          }
        }
      }
    }
  }
}

.modal-backdrop.show {
  background: rgba(0, 0, 0, 0.5) !important;
  opacity: 1 !important;
}

.lead_page {
  .Calldata {
    .Lead_wrapper {
      .form-group {
        p.detail_text {
          audio {
            max-width: 100%;
          }
        }
      }
    }
  }
}
.customer-journey{
  border-bottom: 1px solid rgb(200, 200, 200);
  padding: 10px 0px 10px 20px;
  &:last-child{
    border-bottom: 0;
  }
  .icon-img{
    margin-right: 10px;
    background-color: #f0f0f0;
    border-radius: 3px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      margin: 0px;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .lead_page {
    .main_wrapper {
      .title_wrapper {
        flex-flow: wrap;

        .tags_set {
          width: 49%;
          margin: 0.5%;

          .dropdown-wrapper {
            width: 100%;
            justify-content: space-between;
            flex-flow: wrap;

            .divider.text {
              margin: 0px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .lead_page {
    .dispaly_profile {
      .profile_boxes {
        .profile_left {
          .profile_detail {
            padding-right: 5px;
          }
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .lead_page {
    .Lead_wrapper {
      .form-group {
        p.detail_text {
          .lead-details-star-rating {
            .star.rating {
              display: block;
              white-space: pre-wrap;
            }
          }
        }
      }
    }

    .dispaly_profile {
      .dispaly_profile_detail {
        .profile_content {
          .call_icon {
            min-width: 20px;
          }

          p {
            word-break: break-all;
          }
        }
      }
    }
  }
}

.mentions__control {
  height: 110px;

  textarea.mentions__input {
    font-family: 'Gibson Regular' !important;
    font-size: 16px !important;
  }
}

.notes-wrapper {
  position: relative;
  padding-right: 11%;
}

.notes-wrapper div {
  font-size: 11px !important;
  position: absolute;
  top: -0px;
  right: -0px;
}

.activities-wrapper {
  .button-position {
    display: flex;
    button{
      position: static;
    }
  }
  position: relative;
  background: #fffff7;

  .activities-main:last-child {
    .sl-activities-item::after {
      display: none;
    }
  }

  .dropButton {
    width: 13% !important;
    margin-left: auto;
    margin-bottom: 23px;
  }

  padding: 1rem;

  .activities-main {
    position: relative;
    border-color: rgba(120, 130, 140, 0.13);
    padding-bottom: 2px;
  }

  .sl-content {
    margin-left: 65px;
    margin-bottom: 8px;
    border-radius: 5px;
    padding-top: 0px !important;
    padding-right: 0px !important;
    /* box-shadow: 0 1px 2px rgb(0 0 0 / 15%), 0 -1px 0px #eef2e6; */
    background-color: #fffff7;
    position: relative;
    border: 1px solid #c2c2c2;

    .time-label {
      color: #ccd0c4;
      font-size: 1.1em;
      position: absolute;
      top: 52px;
      right: 12px;
    }

    .time-label2 {
      color: #ccd0c4;
      font-size: 1.1em;
    }

    h2 {
      margin-top: 6px;
      color: #2a3740;
      font-size: 1.2em;
    }

    p.user-name {
      position: absolute;
      top: 4px;
      right: 45px;
      color: #999d91;
      font-size: 1em;
    }

    .ui.button {
      margin-right: 0;
      padding: 11px 20px !important;
    }

    p {
      color: #999d91;
      font-size: 1.1em;
    }
  }

  button {
    font-size: 12px !important;
    position: absolute;
    top: 0;
    right: 0;
  }

  .item-tag {
    border-color: #89aec3 !important;

    // border-left: solid #0071eb !important;
    button {
      background-color: #89aec3 !important;
    }

    .sl-icon:before {
      background-color: #89aec3 !important;
    }
  }

  .item-sms {
    border-color: #5eb4ce !important;

    button {
      background-color: #5eb4ce !important;
    }

    .sl-icon:before {
      background-color: #5eb4ce !important;
    }
  }

  .item-green {

    border-color: #c4e0ac !important;

    button {
      background-color: #c4e0ac !important;
    }

    .sl-icon:before {
      background-color: #c4e0ac !important;
    }
  }

  .item-red {
    border-color: rgb(187, 92, 105);

    button {
      background-color: rgb(187, 92, 105) !important;
    }

    .sl-icon:before {
      background-color: rgb(187, 92, 105) !important;
    }
  }

  .item-blue {
    border-color: #68bec4;

    button {
      background-color: #68bec4 !important;
    }

    .sl-icon:before {
      background-color: #68bec4;
    }
  }

  .item-mention {
    border-color: #53a6fa;

    button {
      background-color: #53a6fa !important;
    }

    .sl-icon:before {
      background-color: #53a6fa;
    }
  }

  .item-warning {
    border-color: #fdab29;

    button {
      background-color: #fdab29 !important;
    }

    .sl-icon:before {
      background-color: #fdab29;
    }
  }

  .sl-activities-item:before {
    content: '';
    position: absolute;
    border-color: inherit;
    border-width: 3px;
    border-style: solid;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    top: 6px;
    left: 0;
    display: none;
  }

  .sl-activities-item:after {
    content: '';
    top: 40px;
    bottom: -40px;
    left: 20px;
    position: absolute !important;
    z-index: 1;
    border-color: #ececec;
    border-left-width: 2px !important;
    border-right-width: 2px !important;
    border-style: solid !important;

  }

  .sl-icon i {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 10px;
    color: #fff;
    font-size: 1.5rem;
    position: relative;
    /* z-index: 50000; */
    top: 40px;
    left: 20px;
  }

  .sl-icon {
    position: absolute;
    left: -8px;
    z-index: 2;
    border: inherit;
    border-width: 0;
  }

  .sl-icon .fa-phone {
    -ms-transform: rotate(90deg);
    /* IE 9 */
    transform: rotate(90deg);
  }

  .sl-icon::before {
    content: '';
    width: 40px;
    height: 40px;
    position: absolute;
    border-color: inherit;
    border-width: 3px;
    border-style: solid;
    border-radius: 50%;
    top: 30px;
    left: 10px;
  }
}

.btn-close {
  padding: 10px 20px;
  font-family: $code-font-stack;
  font-weight: 300;
  color: #fff;
  text-transform: capitalize;
  background: $link-color;
  border: 1px solid $link-color;
  letter-spacing: 1px;
  font-size: 14px;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $link-color;
    background: transparent;
  }
}

.ui.segment.active.tab {
  overflow: hidden;
  padding: 0;
  border: none !important;
  box-shadow: none !important;
&.tracking-code-middle {
    border: 1px solid #acacac !important;
    padding: 31px;
  }
}

div.title_wrapper {
  // align-items: center;
}

div.title_wrapper button.activity-btn {
  background-color: white !important;
  color: #474d66 !important;
  border: 1px solid #c1c4d6;
  padding: 10px 12px;
}

.wrapped {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}


@media screen and (max-width: 600px) {
  .activities-wrapper {
    padding: 0 !important;
  }

  .sl-content.box-responsive {
    display: flex !important;
  }

  .sl-content.box {
    display: none !important;
  }
}

@media screen and (min-width: 601px) {

  .sl-content.box-responsive {
    display: none !important;
  }

  .sl-content.box {
    display: flex !important;
  }
}