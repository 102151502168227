.paymentmethods-wrapper {
  .payment-title-wrapper {
    margin-top: 65px;
  }

  .card-wrapper {
    display: flex;
    margin-top: 31px;
  }

  .input-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;

    input {
      height: 35px;
    }
  }

  .card-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    width: 300px;
    height: 137px;

    border: 1px solid $gray100;

    cursor: pointer;

    img {
      height: 75px;
    }
  }

  .saved-card-title {
    margin-top: 50px;
  }

  .saved-card-holder {
    // display: flex;
    margin-top: 20px;

    thead th {
      font-weight: 600;
    }

    .edit-icon {
      cursor: pointer;
    }

    // .card-box {
    //   width: 400px;
    //   height: 200px;

    //   img {
    //     width: 100%;
    //     height: auto;
    //   }
    // }
  }
}

.add-credit-input-holder {
  padding: 42px 30px 0 45px;
  margin-bottom: 120px;
  
  .input-wrapper {
    .input-error {
      display: flex;
      flex-direction: column;
    }
    .error_msg {
      color: red;
      font-size: 10px;
    }
  }
  
  .input-wrapper:nth-child(4) label {
    margin-right: 107px;

    &:after {
      content: '\f111';

      margin-left: 15px;

      font-family: 'Font Awesome 5 Free';
      font-size: 10px;
      color: $link-color;

      background: $link-color;
      border-radius: 10px;
    }
  }
}

.add-credit-wrapper {
  margin-top: 40px;
  width: 770px;

  border: 1px solid $gray100;

  .ui.button.btn-grey {
    padding: 7px 46px;

    font-size: 20px;
  }

  .ui.button.btn-green {
    padding: 10px 50px;
    margin-right: 0;

    font-size: 20px;
  }

  .input-wrapper:nth-child(4) {
    justify-content: flex-start !important;

    label {
      margin-right: 93px;
    }

    input {
      width: 180px;
    }
  }

  .expiry-holder {
    display: flex;
    align-items: center;

    label {
      margin-right: 70px;
    }
    .add-credit-input-holder {
      input {
        // background-color: #f4f4f4;
        // border: 1px solid #f4f4f4;
        // border-radius: 0;
      }
    }
    input {
      margin-right: 20px;
      width: 85px;
      height: 35px;
    }
  }

  .input-wrapper:nth-child(1),
  .input-wrapper:nth-child(2) {
    input {
      width: 520px;
    }
  }

  .btn-holder {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .add-credit-wrapper {
    .ui.button.btn-grey,
    .ui.button.btn-green {
      font-size: 16px;
    }
  }
}
