input[type="checkbox"].toggle {
  position: absolute;
  left: -99999px;

  opacity: 0;
}

input[type="checkbox"].toggle + label {
  position: relative;

  display: inline-block;
  padding: 0 7px;
  width: max-content;
  height: 40px;

  font-size: 12px;
  font-weight: 200;
  line-height: 40px;

  background-color: #cccccc;
  border-radius: 3px;

  cursor: pointer;
  transition: all .3s ease-in;
}

input[type="checkbox"].toggle + label:before, input[type="checkbox"].toggle + label:hover:before {
  content: " ";

  position: absolute;
  top: 50%;
  left: 8px;
  z-index: 2;

  width: 25px;
  height: 31px;

  background: #ffffff;
  border-radius: 3px;

  transition: all .3s ease-in;
  transform: translateY(-50%);
}

input[type="checkbox"].toggle + label .off, input[type="checkbox"].toggle + label .on {
  color: #ffffff;
}

input[type="checkbox"].toggle + label .off {
  display: inline-block;
  margin-right: 6px;
  margin-left: 38px;
}

input[type="checkbox"].toggle + label .on {
  display: none;
}

input[type="checkbox"].toggle:checked + label .off {
  display: none;
}

input[type="checkbox"].toggle:checked + label .on {
  display: inline-block;
  margin-right: 38px;
  margin-left: 6px;
}

input[type="checkbox"].toggle:checked + label, input[type="checkbox"].toggle:focus:checked + label {
  background-color: #44cb6d;
}

input[type="checkbox"].toggle:checked + label:before, input[type="checkbox"].toggle:checked + label:hover:before, input[type="checkbox"].toggle:focus:checked + label:before, input[type="checkbox"].toggle:focus:checked + label:hover:before {
  top: 50%;
  left: 100%;

  margin-left: -33px;

  background-position: 0 0;
}
