@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');


#limecall-widget{  
  .modal{
    height : 100% !important;
    top : 0 !important;
    left :0 !important;   
  }  
}

div.widget-preview {
  background-color: #5135f7;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 60vh;


   .ui.selection.dropdown{
  height: 30px !important;

  line-height: 0.5em !important;
  .text{
    font-size: 12px !important;
  }
} 


}

.slogan {
  margin: 1.5rem 0;
  padding-top: 2rem;
}

p.description {
  font-size: 1.25rem;
  line-height: 1.52;
  max-width: 28rem;
}

main {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
}

.previewImage {
  display: none;
  flex: 1;
  padding: 1.5rem;
  width: 30vw;
}

.previewImageMobile {
  flex: 1;
  width: 100%;
  padding: 0;
}

article {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  padding: 2rem;
  max-width: 520px;
}

.demo {
  position: absolute;
  font-weight: 600;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1rem;
  text-transform: uppercase;
  border: rgba(255, 255, 255, 0.6) 1px solid;
  padding: 8px 12px;
}

@media screen and (min-width: 64rem) {
  .widget-preview {
    align-items: center;
  }

  .demo {
    font-size: 1.375rem;
  }

  .previewImageMobile {
    display: none;
  }

  .previewImage {
    display: block;
  }

  .slogan {
    margin: 1.5rem 0;
  }
}

.btn-close {
  padding: 10px 20px;
  font-family: $code-font-stack;
  font-weight: 300;
  color: #fff;
  text-transform: capitalize;
  background: $link-color;
  border: 1px solid $link-color;
  letter-spacing: 1px;
  font-size: 14px;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $link-color;

    background: transparent;
  }
}

.widgetconfigpreview-tab{
  background-color: white;
  color: black;
  min-height: 250px;
  border-radius: 5px;
  padding: 15px;
  font-size: 15px;


  .accordion{
    margin-top: 20px;  
    margin-bottom: 20px;  
  }


  .accordion > .title::before{
    content: '';
  }


}
.widgetconfigpreview-title{
  font-family: 'Gibson Regular';
  font-size: 20px;
  font-weight: 400;
  padding-bottom: 10px;
  text-align: center;
}

.widgetconfigpreview-tab .row{
  margin-top: 10px;


 
}

.widgetconfigpreview-tab .row:last-child{
  margin-top: 0px !important;
}

.widgetconfigpreview-tab ol{
  padding: 0;
}



