#signup-modern {    
    background-color: #f2f2f2;
    min-width: 100vw;
    min-height: 100vh;

    div.logo-container{
        padding: 16px 60px !important;
        .logo{
             img{
                height: 36px;                
             }
        }
    }
    div.fullwidth-container{

        padding: 0;

        b{
            font-weight: 600 !important;
        }
        .container{

            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            margin-right: auto;
            margin-left: auto;             
            min-height: 60vh;     
            
             .or{
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 6px;
                max-width: 100%;

            }

           .or:before {
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                left: 0;
                height: 2px;
                background-color: #939393;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
            }

            .or-text {
                z-index: 1;
                padding: 0 8px;
                text-transform: uppercase;   
                background-color: #f2f2f2;
            }         
                         


            .reg-box{


                display:flex;
                flex-direction: row;
                

                .text-section{
                    flex: 0 1 60vw;                
                    
                    
                    .text-container{
                        padding: 10px 40px;


                        h4{
                            margin-bottom: 35px; 
                            font-weight: 500; 
                            margin-top: 10px; 
                            color : #312c27;
                            font-family: 'AvenirLTPro', sans-serif;
                            letter-spacing: -0.5px;
                        }
                        p{
                            font-size: 17px !important;
                            font-weight: 400;
                            margin-top: 0;
                            margin-bottom: 1rem;                           
                            
                            img{
                                margin-right: 6px;
                                width: 28px !important;
                                vertical-align: middle;
                                border-style: none;                               
                            }
                        }                       

                    }
                }
                .form-section{
                    flex: 1 1 40vw;  
                    
                    a {
                        color: black !important;
                        text-decoration: underline !important;
                    }
                    button.btn-login{
                        background-color: #0071eb;
                        cursor: pointer;
                        pointer-events: auto;
                        margin-right: 0;
                        margin-bottom: 26px;
                        width: 100%;
                        height: 38px;
                        font-size: 16px;
                        color: #fff;                                
                        transition: all .2s ease-in-out;
                        padding: 10px 20px;
                        font-family: "Open Sans","DejaVu Sans Mono","Bitstream Vera Sans Mono","Monaco",monospace;
                        font-weight: 600;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                    }


                    .login-google:before {
                        background-image: url('../assets/images/google-logo.svg') !important;
                        opacity: 1;
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 5%;
                        width: 33px;
                        height: 33px;
                        background-position: 50%;
                        background-repeat: no-repeat;
                        background-size: cover;
                        transition: all .2s ease-in-out;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                      }

                      .login-btn-wrapper{
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 16px;
                      }
                      
                      .login-google:after {
                        background-image: url('../assets/images/google-white-logo.svg') !important;
                        content: "";
                        opacity:0;
                        position: absolute;
                        top: 50%;
                        left: 5%;
                        width: 33px;
                        height: 33px;
                        background-position: 50%;
                        background-repeat: no-repeat;
                        background-size: cover;
                        transition: all .2s ease-in-out;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                      }

                    button.login-google{                     
                        
                        position: relative;
                        box-shadow: inset 0 0 0 1px transparent, inset 0 0 0 0 rgb(34 36 38 / 15%);
                        vertical-align: initial;
                        background-color: #fff;
                        border: 1px solid #d3d3d3;                                
                        padding: 12px 0;
                        border-radius: 5px;
                        color: rgba(0,0,0,.6);
                            font-family: Lato,"Helvetica Neue",Arial,Helvetica,sans-serif;
                            margin: 0 0.25em 0 0;   
                            min-height: 1rem;
                    }

                    button.login-google:hover{                     
                        background-color: #0071eb;
                        color: #fff !important;                               
                    }

                    button.login-google:hover + .login-google:after{  
                        opacity: 1
                    }

                    button.login-google:hover ~ .login-google:before{ 
                        opacity: 0
                    }
                    
                     .form-container{
                        margin-bottom: 20px;
                        padding: 30px 20px;
                        
                        padding-bottom: 45px;
                        min-height: 500px;

                        border: 2px solid #e0e0e0;
                        margin-top: 25px;
                        box-shadow: none;
                        border-radius: 18px;
                        background-color: #fff !important;

                            #g-recaptcha{
                                max-height: 78px !important;                               
                                max-width: 304px !important;
                            }                         
                        
                        
                            #needhelp {
                                float: right;
                                padding: 0px;
                                border: none;                                
                                margin-top: -14px;
                                text-transform: inherit;
                                color: gray;
                                font-weight: 400;
                                cursor: pointer;
                                font-size: 0.8rem;
                            }

                            h5{
                                padding-bottom: 15px;
                                padding-left: 0px;
                                padding-right: 0px;
                                padding: 0 5px;
                                margin-top: 25px;
                                margin-bottom: 20px;
                                font-size: 1.375rem;
                                color: #312c27;
                                text-align: center;
                                font-weight: 600;
                            }
                            
                            form{

                                display: block;
                                margin-top: 0em;

                                 .bottomspace{
                                    margin-bottom: 26px;
                                 }

                                 .form-group{
                                     margin-bottom: 0 !important;

                                     .react-tel-input{
                                        .form-control{
                                            padding: 0.375rem 30px !important;
                                        }
                                    }
   &.phone-main{
    .form-control {
                                    padding: 0.375rem 45px !important;
    }
}
                                    .form-control{
                                       border-radius: 4px;
                                       color: #524040;
                                       height: 48px;
                                       display: block;
                                       width: 100%;
                                       padding: 0.375rem 0.75rem !important;
                                       font-size: 1rem;                                   
                                       line-height: 1.5;                                   
                                       background-color: #fff;
                                       background-clip: padding-box;
                                       border: 1px solid #d3d3d3;
                                       transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                                   }
                                 }
                                
                                 
                            }
                        
                    }
                    



                }
            }
        }
        
    }

}     

@media screen and (max-width: 600px){

    div.logo-container .logo{
        display: flex !important;
        justify-content: center  !important; 

    }


    div.fullwidth-container{
        margin: 0 !important;
        margin-top: 60px !important;
        padding: 0 !important;
        width: 100% !important;
        padding-top: 60px !important;


        
    
        
        .form-section{
            margin-top: 100px;
        }

        .reg-box{
            flex-direction: column !important;

             .text-section .text-container {
                padding: 10px 20px !important;
            }

        }
    }
    div.sign-up-container{
        margin-top: 0 !important;
        padding-top: 0 !important;
    }
    .sign-up-form-section{
        margin-top: 0 !important;
    }
}

.cstmSingup{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.sign-up-container{
    margin-top: 0 !important;
    padding-top: 0 !important;
    width: 100%;
    margin: 0 auto;
  }
  
