.integration-container {
  padding: 32px;
  height: 100%;
  margin-top: 45px;
  background: #f4f4f4;

  .integration-wrapper {
    justify-content: center;
    display: flex;
    padding: 40px 48px 220px 29px;

    background: $white;

    .integration-input-btn-holder {
      display: flex;
      justify-content: space-between;

      .input-wrapper {
        flex-basis: 83%;
        width: 100%;

        .input {
          width: 100%;
        }

        input {
          padding: 18px 30px;

          font-family: $gibson;
          font-size: 20px;
        }
      }

      .ui.btn-integration {
        font-family: $gibson;
        font-size: 20px;
        font-weight: 400;
      }
    }

    .integration-create-text {
      margin-top: 22px;
      margin-bottom: 0;     
      font-family: $gibson;
      font-size: 20px;
      font-weight: 400;
    }

    .integration-text {
      margin-bottom: 0;

      font-family: $gibson;
      font-size: 18px;
      font-style: italic;
      font-weight: 400;
      color: $gray300;

      img {
        margin-right: 10px;
      }
    }

    .integration-text-holder {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .integration-search-holder {
        display: flex;

        button {
          margin-right: 0;
        }

        .input-wrapper {
          input {
            padding: 13px 46px;
          }

          i {
            display: none;
          }
        }
      }
    }

    .integration-card-holder {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      margin-top: 68px;

      .integration-card {
        margin-bottom: 30px;
        width: 194px !important;
        position: relative;
        padding-bottom: 40px;
        box-shadow: 5px 3px 25px 10px #f5f5f5;

        .slack {
          margin-left: -12px;
        }

        &:not(:last-of-type) {
          margin-right: 3.2rem;
        }

        &:hover {
          .integration-status {
            color: $white;

            background: $link-color;
            border: 1px solid $link-color;

            cursor: pointer;
            transition: all 0.2s ease-in-out;
          }
        }

        .integration-img-holder {
          // display: flex;
          // justify-content: center;
          // align-items: center;
          padding: 10px 10px;

          // height: 224px;
          .text-wrapper {
            h3 {
              margin-bottom: 5px;
            }

            p {
              font-size: 15px;
              opacity: 0.5;
              margin: 10px 0px 10px 0px;
            }
          }

          img {
            width: 40%;
            min-height: 40px;
          }

          img.integromat-image {
            // background-color: red;
            width: 60% !important;
          }
        }

        .integration-status {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50px;
          position: absolute;
          bottom: 0;
          width: 100%;
          font-family: $gibson;
          font-size: 20px;
          color: $gray300;
          text-align: center;
          background: #007bff;
          color: #fff;
          cursor: pointer;
        }

        .selectedIntegration {
          color: $white;

          background: $link-color;
          border: 1px solid $link-color;
        }
      }
    }
  }
}

.btnmanageSetting {
  font-weight: bold !important;
  margin-bottom: 5px !important;
  background: #d3dce4 none !important;
  margin-left: 11px !important;
  font-size: 7px !important;
}

.ui.modal-inverted-integration {
  padding: 70px 95px;
  width: 1153px;
}

.integration-modal-wrapper {
  .integration-modal-text {
    margin-top: 6px;

    font-family: $gibson;
    font-size: 30px;
    font-weight: 600;
    color: #383838;
    text-align: center;
  }

  .center-img {
    margin: 40px 0;

    text-align: center;
  }

  .integration-input-wrapper {
    .api-text {
      margin-top: 12px;
      margin-bottom: 0;

      font-family: $gibson;
      font-size: 20px;
      font-weight: 500;

      span {
        font-size: 12px;
        font-weight: 500;
        color: #f54903;
      }
    }

    .integration-breadcrumb {
      font-family: $gibson;
      font-size: 20px;
      font-weight: 300;

      span {
        color: $link-color;
      }
    }

    .input-wrapper {
      margin-top: 34px;

      .ui.input {
        width: 100%;

        font-size: 20px;
      }

      i {
        display: none;
      }
    }
  }

  .integrate-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    .btn-continue,
    .btn-cancel {
      padding: 20px 40px;

      font-size: 20px;
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .integration-container {
    height: 100%;

    .integration-wrapper {
      padding: 40px 48px 20px 29px;

      .integration-create-text {
        font-size: 16px;
      }

      .integration-text {
        font-size: 14px;
      }

      .integration-card-holder {
        padding-right: 0;
        margin-top: 5px;

        .integration-card {
          width: 165px;
          position: relative;

          .integration-img-holder {
            height: 165px;
          }
        }
      }

      .integration-input-btn-holder .input-wrapper {
        flex-basis: 69%;
      }
    }
  }

  .ui.modal-inverted-integration {
    padding: 70px 66px;
    width: 972px;
  }
}

.settings {
  .integration_main {
    .integration-card-holder {
      .integration-card {
        .integration-img-holder {
          min-height: 190px;
        }

        .btn_disconnect {
          background-color: #f54903;
          color: #e8e8e8;
        }

        .btn_disconnect:hover {
          background: #f54903;
          border-color: #f54903;
          opacity: 1;
          color: #e8e8e8;
          border: 1px solid #f54903;
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .settings {
    .integration_main {
      .integration-card-holder {
        justify-content: center;

        .integration-card {
          margin: 11px !important;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .settings {
    .integrations {
      padding: 26px 0px;
    }

    .integration_main {
      .integration-card-holder {
        justify-content: center;

        .integration-card {
          margin: 11px !important;
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .settings {
    .integration_main {
      .integration-card-holder {
        justify-content: center;

        .integration-card {
          margin: 11px !important;
        }
      }
    }
  }
}

.btn-close {
  padding: 10px 20px;
  font-family: $code-font-stack;
  font-weight: 300;
  color: #fff;
  text-transform: capitalize;
  background: $link-color;
  border: 1px solid $link-color;
  letter-spacing: 1px;
  font-size: 14px;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $link-color;

    background: transparent;
  }
}
