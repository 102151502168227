@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

* :not(button) {
  animation-name: fadeIn;
  animation-duration: .3s;
}
