.callsettings-wrapper {
  padding: 50px 0 20px 33px;
  width: 1065px;
  max-width: 100%;

  .ui.button.btn-blue {
    padding: 10px 20px;
    font-family: 'Open Sans', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono',
      'Monaco', monospace;
    font-weight: 600;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
  }
}
.allowed-countries-wrapper {
  width: 1065px;
  max-width: 100%;

}

.toggle-group {
  margin-bottom: 53px;
  width: 100%;

  &:last-of-type {
    margin-bottom: 32px;
  }

  .toggle-wrapper {
    display: flex;
    justify-content: space-between;
    width: 75%;
    max-width: 100%;
  }

  .input-with-toggle {
    display: flex;
    align-items: center;
  }

  .input-with-toggle input {
    width: 55px;
    padding: 10px;
  }

  .input-with-toggle p {
    padding-left: 10px;
  }

  .toggle-holder {
    display: flex;
    align-items: center;
  }

  // .toggle-content {
  //   padding: 0 150px 0 0;
  // }
}

.call-title {
  margin-bottom: 18px;

  font-family: $gibson;
  font-size: 16px;
  font-weight: 500;
}

.call-description {
  width: 545px;
  max-width: 100%;

  font-family: $gibson;
  font-size: 14px;
  font-weight: 400;
  color: #b3b3b3;
}
.call-bottom-wrapper .file__validation {
  color: #b3b3b3;
  padding: 10px 0 0 0;
}
.call-bottom-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 83px;
  width: 1000px;
  max-width: 100%;

  .call-bottom-holder {
    flex-basis: 28%;
  }

  .call-textarea-description {
    width: 200px;
    max-width: 100%;

    font-family: $gibson;
    font-size: 16px;
    line-height: 19px;
    color: #b3b3b3;
  }

  .call-textarea-holder {
    padding-left: 51px;
    width: 100%;

    .call-textarea {
      width: 100%;
    }
  }
}

.call-algorithm {
  .call-bottom-holder {
    flex: 0 0 40%;
    max-width: 30%;
  }

  .ui.form {
    display: flex;
    align-items: center;

    .field {
      margin: 0 50px 0 0;
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .call-title {
    font-size: 16px;
  }

  .call-description {
    font-size: 14px;
  }

  .call-bottom-wrapper {
    .call-textarea-description {
      font-size: 14px;
    }
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .callsettings-wrapper {
    .call-bottom-wrapper {
      .call-bottom-holder {
        audio {
          max-width: 100%;
        }
      }
    }
  }
  // .main-container {
  //   overflow-x: hidden;
  // }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .activeComponent-wrapper {
    .call-schedules,
    .countries-settings,
    .callsettings-wrapper,
    .sms-settings-wrapper,
    .blacklist-settings,
    .callerID-settings-wrapper {
      padding: 10px 0px 0px 0px;
    }
  }
}
