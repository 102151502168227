.register-wrapper {
  display: flex;
  height: fit-content;

  .register-title-description {
    margin-bottom: 43px;
    font-size: 20px;
  }

  .register-form {
    position: relative;
    z-index: 2;

    flex-basis: 50%;
    padding: 37px 42px 0;

    background: $white;
    box-shadow: 5px 12px 28px 10px #e4e4e4;

    .resigter-form-holder {
      .input-wrapper {
        display: flex;
        flex-direction: column;
        margin: 20px 0 25px;

        .input {
          width: 100%;

          input {
            width: 100%;
          }
        }

        .input-title {
          margin-bottom: 5px;
          margin-left: 25px;
        }
      }

      .checkbox-register-holder {
        margin-top: 44px;
      }
    }

    .register-description-link {
      font-size: 16px;
      color: #9f9f9f;

      a {
        color: #2ea1f4;

        &:hover {
          color: #000000;

          cursor: pointer;
          transition: all .2s ease-in-out;
        }
      }
    }

    .checkbox-register-holder {
      label {
        color: #9f9f9f;
      }

      input:focus ~ label {
        color: #9f9f9f;
      }
    }

    .terms {
      margin: 31px 0 0 0;

      a {
        &:hover {
          color: #000000;

          cursor: pointer;
          transition: all .2s ease-in-out;
        }
      }
    }

    .btn-account {
      padding: 17px 138px;
      margin: 20px 0;

      font-size: 16px;
      color: #ffffff;
      text-transform: uppercase;

      background: #283238;

      @include on-event {
        color: #283238;

        background: $white;
        border: 1px solid #283238;
      }
    }
  }

  .register-form-wrapper {
    padding: 40px 205px 50px 111px;

    .register-title {
      margin-bottom: 6px;

      font-family: $gibson;
      font-size: 30px;
      font-weight: 600;
    }
  }

  .input-invi-wrapper:nth-child(3) {
    margin-bottom: 25px;

    .input-wrapper {
      margin-bottom: 0;
    }
  }
}

.register-decription-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 50%;
  padding: 10px 180px 10px 180px;

  background: $gray100;

  .register-title {
    font-family: $gibson;
    font-size: 30px;
    font-weight: 600;
  }

  .italic-text {
    padding-right: 85px;

    font-size: 20px;
    font-style: italic;
    font-weight: 300;
  }

  .sales-director-holder {
    display: flex;
  }

  .director-position-holder {
    display: flex;
    align-items: center;
  }

  .direction-position {
    display: flex;
    align-items: center;

    &:after {
      content: "|";

      margin: 0 5px;

      font-weight: 600;
      color: $gray300;
    }
  }

  .director-name {
    margin: 0;

    font-family: dolce, sans-serif;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
  }

  .trustedby-holder {
    margin-top: 190px;

    .trusted-text {
      font-size: 20px;
      color: $gray300;
      text-transform: uppercase;
    }

    .trusted-logo-holder {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1800px) {
  .register-wrapper {
    .register-form .btn-account {
      padding: 17px 128px;
    }

    .register-form-wrapper {
      padding: 40px 40px 50px 40px;
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1549px) {
  .register-wrapper {
    margin-left: 50px;
    height: fit-content;

    .register-form-wrapper {
      padding: 20px 0 0 0;

      input {
        font-size: 16px;
      }

      .ui.checkbox label {
        font-size: 14px;
      }

      .register-title {
        font-size: 28px;
      }
    }

    .register-form {
      padding: 37px 70px 45px;

      .btn-account {
        padding: 17px 95px;
      }

      .register-description-link {
        font-size: 14px;
      }
    }

    .register-title-description {
      margin-bottom: 30px;

      font-size: 16px;
    }
  }

  .register-decription-holder {
    padding: 50px 30px;

    .register-title {
      font-size: 28px;
    }

    .italic-text {
      padding-right: 0;

      font-size: 16px;
    }

    .trustedby-holder {
      .trusted-text {
        font-size: 16px;
      }
    }
  }
}

.btn-close {
  padding: 10px 20px;
  font-family: $code-font-stack;
  font-weight: 300;
  color: #fff;
  text-transform: capitalize;
  background: $link-color;
  border: 1px solid $link-color;
  letter-spacing: 1px;
  font-size: 14px;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $link-color;

    background: transparent;
  }
}
