.nav-container {
  position: fixed;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: $nav-width;
  height: 100%;

  color: $green;

  background-color: #000000;

  .top-nav {
    display: flex;
    flex-direction: column;
    padding: 0 0 0px;
    height: 56%;

    .menu-link {
      position: relative;
      text-decoration: none;

      display: flex;
      justify-content: center;
      align-items: center;
      // padding: 28px 0;
      padding: 5px;
      width: 100%;

      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:first-child {
        // padding: 39px 0;
      }

      .icon-wrapper {
        position: relative;
        width: 100%;
        text-align: center;
        display: flex;
        padding: 5px;
        span{
          font-size: 12px;
          color: #fff;
          word-break: break-all;
          display: block;
          margin-top: 6px;

          &:hover {
            text-decoration: none;
          }
        }
      }

      .hover-name-wrapper {
        position: absolute;
        right: -117px;

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        width: 110px;

        color: $white;

        background: #465259;

        opacity: 0;
        pointer-events: none;
        transition: all 0.4s ease-in-out;

        i {
          position: absolute;
          left: -7px;

          font-size: 21px;
          color: #465259;
        }

        p {
          font-size: 12px;
        }
      }

      img {
        max-width: 100%;
        // position: absolute;
        // top: 50%;
        // left: 50%;

        transition: all 0.2s ease-in-out;
        width:20px !important;
        margin-right: 5px;
        color: #fff;
        // transform: translate(-50%, -50%);
      }

      &:hover {
        background: $hover-color-nav;

        cursor: pointer;
        transition: all 0.2s ease-in-out;

        .hover-name-wrapper {
          opacity: 1;
        }
      }
    }
  }

  .bottom-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;

    .menu-link {
      position: relative;
      text-decoration: none;

      display: flex;
      justify-content: center;
      align-items: center;
      // padding: 28px 0;
      padding: 5px;
      width: 100%;

      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:first-child {
        padding: 5px;
      }

      .icon-wrapper {
        position: relative;
        width: 100%;
        text-align: center;
        display: flex;
        padding: 5px;
margin-left: 15px;
        span{
          font-size: 12px;
          color: #fff;
          word-break: break-all;
          display: block;
          margin-top: 4px;

          &:hover {
            text-decoration: none;

          }
        }
      }

      .hover-name-wrapper {
        position: absolute;
        right: -117px;

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        width: 110px;

        color: $white;

        background: #465259;

        opacity: 0;
        pointer-events: none;
        transition: all 0.4s ease-in-out;

        i {
          position: absolute;
          left: -7px;
          font-size: 21px;
          color: #465259;
        }

        p {
          font-size: 12px;
        }
      }

      img {
        max-width: 100%;
        transition: all 0.2s ease-in-out;
        width: 20px;
        margin-right: 5px;
      }

      &:hover {
        background: $hover-color-nav;

        cursor: pointer;
        transition: all 0.2s ease-in-out;

        .hover-name-wrapper {
          opacity: 1;
        }
      }
    }
  }

  .nav-active {
    background-color: $hover-color-nav !important;
  }

  // .menu-link .active, .menu-link.nav-active .inactive {
  //   opacity: 0;
  // }

  // .menu-link.nav-active .active {
  //   opacity: 1;
  // }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .nav-container {
    // width: 47px;

    .top-nav {
      .menu-link:first-child {
        // padding: 39px 0;

        .active,
        .inactive {
          width: 20px;
        }
      }

      .menu-link {
        // padding: 25px 0;

        .active,
        .inactive {
          width: 18px;
        }
      }
    }

    .bottom-nav {
      .menu-link:first-child {
        // padding: 39px 0;

        .active,
        .inactive {
          width: 20px;
        }
      }

      .menu-link {
        // padding: 25px 0;

        .active,
        .inactive {
          width: 18px;
        }
      }
    }
  }
}

@include respond-to(xs) {
  .nav-container {
    z-index: 5;
    width: 50px;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .custome_nav_bar {
    width: 50px;
  }

  .header_fixed {
    width: 100%;
    left: 0 !important;
  }


  .menu-link:first-child {
    padding-top: 150px !important;
  }


}

@media (min-width: 576px) and (max-width: 767px) {
  .dashboard-wrapper.inner-main-contaner.settings {
    width: 90%;
    margin: auto;
  }
}

@media (min-width: 360px) {
  .menu-link:first-child {
    padding-top: 130px !important;
  }
}

@media (min-width: 480px) and (max-width: 575px) {
  .menu-link:first-child {
    padding-top: 100px !important;
  }
}

@media (min-width: 576px) {
  .menu-link:first-child {
    padding-top: 5px !important;
    
  }
  .menu-link.sub-menu:first-child {
    padding-top: 0px !important;
    
  }
}


.count {
  position: absolute;
  right: 24px;
    top: -6px;
      background-color: red;
      min-width: 20px;
      height: 20px;
      border-radius: 100%;
      line-height: 20px;
      font-size: 12px !important;
}
// .MuiSlider-thumb{
//   display: none !important;
// }

.main-box{
  padding: 10px 6px;
  display: flex;
  flex-direction: column;
  gap: 6px 0;
  color: #fff;
  width: 110px;
  background-color: #1a1a1a;
  border: 1px solid #4d4d4d;
  border-radius: 4px;
  .profile-content p {
    font-size: 14px;
    font-weight: 600;
  }
  p {
    margin: 0;
    font-size: 10px;
    font-weight: 500;
    b {
      font-weight: 600;
    }
  }
  .sidebar-progress.ui.progress {
    margin: 5px 0;
    height: 0.5em;
    background-color: #fff2;
    border-radius: 10px;
    .bar{
      height: 0.5em;
      border-radius: 10px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      background-color: #1976d2;
      min-width: 0;
    }
  }
  .count-list {
    display: flex;
    align-items: flex-start;
    svg {
      height: 15px;
      width: 15px;
      vertical-align: baseline;
    }
    p {
      margin-left: 6px;
      line-height: 16px;
    }
  }
  .sidebar-btn.ui.button{
    background-color: #186bcc;
    border: 1px solid #4d4d4d;
    color: #fff;
    font-size: 12px !important;
    padding: 8px 5px;
    text-transform: capitalize;
    font-family: 'Gibson Regular';
    font-weight: 500;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, .8) !important;
  }
}