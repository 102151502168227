.chart-wrapper {
  // padding: 0 63px 50px;
  // margin-left: 79px;
  // width: -webkit-fill-available;
  padding: 20px 50px;
  // height: 387px;

  // background: $gray100;

  canvas.chartjs-render-monitor {
    width: -webkit-fill-available !important;
    height: 100% !important;
  }

  h2.chart-title {
    padding-top: 42px;
    margin-top: 0;

    font-family: $gibson;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
  }

  .chart-holder {
    background: #ffffff;

    border-radius: 5px;
    border: 1px solid rgba(0,0,0,.2);
    box-shadow: 0 4px 14px 0 rgba(0,0,0,.1);
    transition: all 0.3s ease-in-out;

    &:hover {
      border: 1px solid  #286efa;
    }
  }

  .chart-box {
    padding: 38px;
    height: 300px;

    background: #ffffff;
  }
}

//visitor graph
.visitor-graph-holder {
  height: 100%;

  .chart-title {
    margin-top: 28px;

    font-family: $gibson;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
  }

  .chart-holder {
    height: 100%;
  }

  .chart-box {
    height: auto;
  }

  canvas {
    height: 338px !important;
  }
}
