.color-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
  width: 895px;

  .color-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;

    .rc-color-picker-wrap {
      display: flex;
      align-items: center;
    }

    .rc-color-picker-trigger {
      width: 60px;
      height: 40px;

      border: none;
    }

    button.ui.button.btn-colorpicker {
      padding: 11px 14px;
      margin-right: 0;

      border-radius: 1px;
    }
  }

  .color-title {
    margin-bottom: 0;

    font-family: 'Gibson Regular';
    font-size: 20px;
    font-weight: 500;
  }
}
