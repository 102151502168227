#admin-verification{    
    background-color: #F6FFFF;
    min-width: 100vw;
    min-height: 100vh;
 
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .container{
        background-color: #fff;
        border: 1px solid grey;
        width:25%;
        padding: 32px 36px;
        h2{
            font-size: 1.625rem;
            line-height: 3rem;
            font-weight: 500;
            margin-bottom: 2rem;
            letter-spacing: -1px;
            font-family: Slack-Lato, appleLogo, sans-serif;
            text-align: center;
        }

        p{
            text-align: justify;
        }
    }

    div.logo-container{
        padding: 16px 24px !important;
        .logo{
             img{
                height: 36px;                
             }
        }
    }


}