$label-colour:  #CD5C5C;
$disabled-colour: #ddd;
$toggle-colour: #2F855A;
$white: #fff;


.toggle-switch {
    margin-top:-7px !important;
    position: relative;
    width: 105px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
    &-checkbox {
      display: none;
    }
    &-label {
      display: block;
      overflow: hidden;
      cursor: pointer;
      border: 0 solid $label-colour;
      border-radius: 20px;
      margin: 0;
    }
    &-inner {
      display: block;
      width: 200%;
      margin-left: -100%;
      transition: margin 0.3s ease-in 0s;
      &:before,
      &:after {
        display: block;
        float: left;
        width: 50%;
        height: 34px;
        padding: 0;
        line-height: 34px;
        font-size: 14px;
        color: white;
        font-weight: bold;
        box-sizing: border-box;
      }
      &:before {
        content: attr(data-yes);
        text-transform: uppercase;
        padding-left: 10px;
        background-color: #1976d2;
        color: #fff;
      }
    }
    &-disabled {
      background-color: $disabled-colour;
      cursor: not-allowed;
      &:before {
        background-color: $disabled-colour;
        cursor: not-allowed;
      }
    }
    &-inner:after {
        content: attr(data-no);
      text-transform: uppercase;
      padding-right: 10px;
      background-color: $label-colour;
      color: $white;
      text-align: right;
    }
    &-switch {
      display: block;
      width: 24px;
      margin: 5px;
      background: $white;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 70px;
      border: 0 solid $label-colour;
      border-radius: 20px;
      transition: all 0.3s ease-in 0s;
    }
    &-checkbox:checked + &-label {
      .toggle-switch-inner {
        margin-left: 0;
      }
      .toggle-switch-switch {
        right: 0px;
      }
    }

    @media screen and (max-width: 991px) {
        transform: scale(0.9);
      }
      @media screen and (max-width: 767px) {
        transform: scale(0.825);
      }
      @media screen and (max-width: 575px) {
        transform: scale(0.75);
      }

  }


