body.dimmed.dimmable {
    overflow-y: auto !important;
}

.meet-title-holder {
        float: right;
    padding: 15px 30px 15px 122px;

    .filterSection {
        @media(max-width:575px) and (min-width:320px) {
            display: block !important;
        }
    }

    .metting-filter-section {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @media(max-width:767px) and (min-width:576px) {
            margin-top: 0px;
            -webkit-flex-flow: column;
            flex-flow: column;
            -webkit-align-items: flex-start;
            align-items: flex-start;
            width: 35%;
            margin-right: 10px;
        }

        @media(max-width:575px) and (min-width:320px) {
            width: 100%;
            margin-bottom: 10px;
            justify-content: flex-start;
        }

        .ui.checkbox {
            label {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                color: #171B26;
                margin: 0;
            }

            label:before {
                border: 1px solid #E6E8EE;
                border-radius: 2px;
                width: 20px;
                height: 20px;
            }

            label:after {
                top: 2px;
                left: 4px;
            }
        }

        .date-selct-metting {
            input {
                border: 1px solid #E6E8EE;
                padding: 6px 9px;
                border-radius: 4px;
                margin-left: 14px;
                margin-right: 14px;
            }
        }

        .headerContact {
            right: 28%;
            position: relative;
        }

        .filter-metting {
            position: relative;

            .selection.dropdown {
                padding: .78571429em 2.1em .78571429em 41px;
                border: 1px solid #E6E8EE;
                border-radius: 4px;

                .divider.default.text {
                    color: #000 !important;
                }
            }

            i {
                display: none;
            }

            img {
                position: absolute;
                top: 50%;
                left: 7px;
                transform: translateY(-50%);
                ;
            }
        }

    }

    .schedule_meeting {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media(max-width:991px) and (min-width:320px) {
            display: block;
        }

        .filterSection {
            display: flex;
        }

        .scheduleFilterWrap {
            display: flex;
            align-items: center;

            @media(max-width:1023px) and (min-width:992px) {
                width: 50%;
            }

            @media(max-width:991px) and (min-width:576px) {
                margin-bottom: 10px;
            }

            @media(max-width:575px) and (min-width:320px) {
                margin-bottom: 10px;
                display: block;
            }

            .chattingbtn {
                border-radius: 4px;
                background: #A7B0FF;
                padding: 9px 13px;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                color: #fff;
                cursor: pointer;
                margin-right: 20px;
                width: 100%;
                max-width: 128px;
                border: 0;
                display: flex;
                align-items: center;
                margin-left: 10px;
                text-transform: capitalize;

                @media(max-width:1023px) and (min-width:768px) {
                    max-width: 110px;
                    padding: 9px 13px;
                    margin-right: 5px;
                }

                @media(max-width:575px) and (min-width:320px) {
                    margin: 10px 0px 0px 0px;
                }

                img {
                    padding-left: 0;
                }

                label {
                    margin: 0px 5px 0px 5px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;

                }
            }

            .segmentbtn {
                border-radius: 4px;
                background: #fff;
                padding: 11px 7px;
                font-size: 14px;
                color: #009FAD !important;
                cursor: pointer;
                margin-right: 20px;
                width: 100%;
                max-width: 210px;
                border: 0;
                display: -webkit-flex;
                display: flex;
                -webkit-align-items: center;
                align-items: center;
                text-transform: capitalize;

                img {
                    margin-right: 5px;
                    padding-left: 0;
                }

                label {
                    margin: 0px 5px 0px 5px;
                }
            }
        }

        .ui.selection.dropdown {
            border: 1px solid #009FAD;
            color: #009FAD;
            padding: 10px 32px;
            border-radius: 4px;
            max-width: 100%;
            width: 220px;

            @media(max-width:1023px) and (min-width:768px) {
                padding: 10px 6px 10px 26px;
                width: 220px;

            }

            i {
                display: none;
            }

            .divider.default.text {
                color: #009FAD !important;
            }
        }

        button {
            border: 1px solid #009FAD;
            border-radius: 4px;
            background: #fff;
            padding: 9px 13px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #009FAD;
            cursor: pointer;
            margin-right: 20px;

            img {
                padding-left: 8px;
            }


        }
    }

    .meeting-time {
        button {
            border: 1px solid #009FAD;
            border-radius: 4px;
            background: #009FAD;
            padding: 9px 13px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #fff;
            cursor: pointer;
        }
    }

}

.metting-event {
  width: calc(100% - $nav-width);
  float: right;
    padding: 15px 30px 15px 63px;

    .ui.pointing.secondary.menu {
        border-bottom: 1px solid #E6E8EE;
        margin-bottom: 0;
    }

    .ui.segment {
        box-shadow: none;
        border: none;
        margin-top: 0;
        padding-top: 0;


        .tablepopup {
            width: 90%;
        }

        table.ui.single {
            border: 0;



            thead {
                tr {
                    th {
                        color: #A4ABC5;
                        font-size: 16px;
                        font-weight: 400;
                        background: #fff;
                        border-bottom: 0;
                        border-top: 0;

                    }

                }
            }

            tbody {
                tr:hover {
                    td {
                        background-color: #EFFAFC;
                    }

                    .social-box-wrapper {
                        opacity: 1 !important;
                    }
                }

                tr:nth-child(3n) td {
                    border-bottom: 1px solid #E6E8EE;
                }

                tr:hover td:nth-child(1) {
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                }

                tr:hover td:last-child(1) {
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                }

                tr {
                    td {
                        border-top: 0;
                    }

                    .date-set-table-boxes {
                        display: flex;
                        align-items: center;
                        color: #171B26;
                        font-weight: 500;
                        font-size: 20px;
                        padding: 11px 10px 12px 29px;
                    }

                    td.date-set-table {

                        img {
                            max-width: 30px;
                        }

                        p {
                            margin-left: 10px;
                            font-size: 16px;
                            margin-bottom: 0;
                        }
                    }

                    td.time-set-table {
                        font-size: 16px;
                        color: #171B26;
                        padding: 20px 11px 0px !important;

                        .phone-call-set {
                            display: flex;
                            align-items: center;

                            img {
                                width: 30px;
                            }

                            p {
                                margin-left: 10px;
                            }
                        }
                    }

                    td.metting-name {
                        color: #171B26;
                        font-size: 16px;
                        font-weight: 500;
                        padding: 20px 11px 0px !important;

                        p {
                            margin: 0;
                        }
                    }

                    td.metting-name p:nth-child(2) {
                        color: #A4ABC4;
                        margin: 0;
                        font-size: 14px;
                    }

                    td.link-table {
                        color: #000;
                        font-weight: 500;
                        font-size: 16px;
                        padding: 20px 11px 0px !important;

                        img {
                            margin-left: 5px;
                        }
                    }

                    td.host-table {
                        padding: 20px 11px 0px !important;

                        .leadtable {
                            display: flex;
                            align-items: baseline;

                            img {
                                width: 10px;
                                height: 10px;
                            }

                            p {
                                margin-left: 6px;
                            }
                        }




                        .host-profile {
                            display: flex;
                            align-items: center;

                            img.circular.image {
                                width: 30px !important;
                            }

                            p {
                                color: #171B26;
                                font-weight: 500;
                                font-size: 16px;
                                margin-left: 10px;
                            }
                        }
                    }

                    td.location-table {
                        .location-img {
                            display: flex;
                            align-items: center;

                            img.circular.image {
                                width: 30px !important;
                            }

                            p {
                                color: #171B26;
                                font-weight: 500;
                                font-size: 16px;
                                margin-left: 10px;
                            }
                        }
                    }

                    .social-icon-set {
                        .social-box-wrapper {
                            display: flex;
                            align-items: center;
                            opacity: 0;
                            transition: all 0.3s;
                            justify-content: center;

                            .social-boxes {
                                width: 40px;
                                height: 40px;
                                border-radius: 50px;
                                background: #009FAD;
                                justify-content: center;
                                align-items: center;
                                display: flex;
                                margin: 0px 3px;

                                img {
                                    width: 20px !important;
                                }


                            }
                        }
                    }
                }
            }
        }
    }

    .secondary.menu {
        .active.item {
            border-color: #009FAD !important;
        }

        .item {
            color: #A4ABC5;
            font-size: 16px;
            width: 16%;
            justify-content: center;
        }
    }
}

.details-popup::-webkit-scrollbar {
    width: 0px;
}

.details-popup.active {
    transform: translateX(0%);
}

.details-popup {
    position: fixed;
    top: 0;
    right: 0;
    width: 390px;
    z-index: 9999;
    background: #fff;
    height: 100%;
    box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.2);
    // overflow: auto;
    transform: translateX(100%);
    transition: all 0.5s;

    @media(max-width:425px) and (min-width:320px) {
        width: 310px;
    }

    .details-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 17px 20px;
        box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.10);

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            color: #262B3F;
            margin: 0;
        }

        img {
            cursor:pointer
        }

        .details-title-icon {
            img {
                margin-left: 15px;
            }
        }
    }



    .details-popup-profile {
        padding: 17px 20px;
        background: #fff;
        border-radius: 10px;
        display: block;
        align-items: flex-start;

        .details-popup-profile-detail {
            padding-left: 20px;
            // text-align: center;
            // margin-top: 20px;

            @media(max-width:425px) and (min-width:320px) {
                padding-left: 12px;
            }

            .details-popup-title {
                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                text-align: center;
                color: #262B3F;
                margin-bottom: 0;
            }

            .employee-title {
                text-align: center;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                color: #A4ABC4;
                margin: 5px 0px;
            }

            .details-number {
                text-align: center;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                color: #171B26;

                img {
                    padding-right: 8px;
                }
            }

            .social-box-wrapper {
                display: flex;
                align-items: center;
                transition: all 0.3s;
                justify-content: center;

                .social-boxes {
                    width: 40px;
                    height: 40px;
                    border-radius: 50px;
                    background: #009FAD;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    margin: 0px 15px;

                    img {
                        width: 20px !important;
                    }

                    .callMobile {
                        width: 14px !important;
                    }
                }

                label {
                    margin: 10px 20px 0px 20px;
                }
            }
        }
    }

    .lead-right-side-bar,
    .contact-side-bar{
        .ui.secondary.pointing.menu{
            // width: 80% !important;
        }
        .contact-card {
           height: 480px !important; 
        }
    }

    .contact-popup-detail {
        .secondary.menu {
            justify-content: space-between;
            width: 100% !important;
            a.item {
                width: auto;
                text-align: center;
                justify-content: center;
            }

            a.active.item {
                border-color: #009FAD;

            }
        }

        .contact-card {
            background: #F5F5F5;
            padding-top: 1px;
            margin-top: -15px;
            overflow-y: scroll;
            height: 500px;
            .ui.dropdown .menu{
                width: 100%
            }

            .contact-card-boxes {
                background: #FFFFFF;
                border-radius: 8px;
                margin: 20px;
                padding: 15px;

                .row {
                    margin: 0;
                    padding: 15px 15px 15px 15px;

                    [class*="six wide"].column {
                        width: 37.5% !important;
                        font-size: 16px;
                        padding: 3px 0px !important;
                    }

                    [class*="ten wide"].column {
                        width: 51.5% !important;
                        font-size: 16px;
                        padding: 3px 0px !important;

                    }

                    .wide.column {
                        padding: 0;
                    }

                    p.card-boxes-right-text {
                        color: #171B26;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        padding: 5px 0px;
                    }

                    p.card-boxes-left-text {
                        color: #171B26;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        padding: 5px 0px;
                    }
                }

                p.card-boxes-title {
                    font-style: normal;
                    font-size: 14px;
                    color: #171B26;
                    font-weight: 600;
                    margin: 0;
                    text-transform: uppercase;

                }
            }
        }
    }
}
.contact-detail-drawer {
    &.details-popup {
        width: 560px;
    }
}


@media (min-width:992px) and (max-width:1199px) {

    .metting-event {
        .secondary.menu {
            .item {
                width: 25%;
            }
        }

        .ui.segment {
            overflow-x: auto;
        }
    }
}

@media (min-width:768px) and (max-width:991px) {
    .meet-holder {
        .meet-title-holder {
            flex-flow: column;
            align-items: flex-start;
        }
    }

    .metting-event {
        .secondary.menu {
            .item {
                width: 35%;
            }
        }

        .ui.segment {
            overflow-x: auto;
        }
    }
}

@media (min-width:320px) and (max-width:767px) {
    .ContactTitle {
        flex-flow: column;
        align-items: flex-start;
        padding: 15px 30px 15px 63px;

        .welcome-name {
            padding-left: 0;
        }

        .contactSearchbar {
            margin: 5px 0px 10px 0px;
            width: 100%;

            .ui.input {
                width: 100%;
            }
        }
    }

    .meet-holder {
        padding-top: 65px;

        .meet-title-holder {
            padding: 15px 30px 15px 63px;
            flex-flow: column;
            align-items: flex-start;

            .schedule_meeting {
                flex-flow: column;
                align-items: flex-start;
            }

            .metting-filter-section {


                .date-selct-metting {
                    width: 200px !important;
                    margin-left: 0;

                    .react-datepicker-wrapper {
                        width: 100%;

                        input {
                            width: 100%;
                            margin: 0;
                        }
                    }

                    input {
                        margin: 14px 0px;
                    }
                }
            }
        }
    }


    .metting-event {
        padding: 15px 30px 15px 45px;

        .secondary.menu {
            .item {
                width: 49%;
                font-size: 12px;
            }
        }

        .ui.segment {
            overflow-x: auto;

            table.ui.single {
                tbody {
                    tr {
                        .date-set-table-boxes {
                            padding: 12px 0px;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width:576px) and (max-width:767px) {
    .meet-holder {
        .meet-title-holder {
            padding: 15px 30px 15px 70px !important;
        }
    }

    .ContactTitle {
        padding: 15px 30px 15px 10px;
    }
}

.filter-metting.headerContact {
    right: 28%;
    position: relative;

}

.addcontactIcon {
    margin-left: 9px;
    position: absolute;
    z-index: 1;
    margin-top: 10px;
}

.searchhead {
    right: 45%;
    position: relative;
    width: 300px;

    .input-gray {
        width: 100%;
    }
}

.dataSearch {
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
    pointer-events: none;
}

.date-selct-metting.date-selct-metting .react-datepicker-wrapper {
    position: relative;
}

.meet-title-holder {
    .schedule_meeting {
        .filterSection {
            .contact-button {
                background: #009FAD;
                color: #fff;
                font-size: 11px;
                letter-spacing: 0;
                position: relative;
                padding: 9px 13px 9px 43px;


                img {
                    position: absolute;
                    top: 50%;
                    left: 15px;
                    transform: translateY(-50%);
                    z-index: 1;
                    padding: 0;
                    margin: 0;


                }
            }
        }
    }
}

.filterSection {
    .metting-filter-section {
        .date-selct-metting {
            position: relative;

            .dropdown.datedropdown {
                padding: 10px 10px;
                border-color: #ccc;
                width: 200px !important;
                margin-right: 15px;

                .divider.default.text {
                    color: #A4ABC4 !important;
                }
            }

            img {
                position: absolute;
                top: 50%;
                right: 27px;
                transform: translateY(-50%);
                z-index: 1;

                @media (max-width: 575px) and (min-width: 320px) {
                    right: 10px;
                }
            }
        }
    }
}

.date-selct-metting .datedropdown {
    border: 1px solid #E6E8EE;
    padding: 10px 10px;
    border-radius: 4px;
    max-width: 100%;
    width: 172px;

    img {
        position: absolute;
        top: 27%;
        right: 16%;
    }
}

.MainModal {
    @media(min-width:576px) {
        width: 390px !important;
    }

    @media(min-width:320px)and (max-width:575px) {
        width: 300px !important;
    }

    .modalMainWrap {
        display: flex !important;
        justify-content: space-between;

        @media(min-width:320px)and (max-width:575px) {
            padding: 10px 15px !important;
        }

        h1 {
            font-size: 25px;

            @media(min-width:320px)and (max-width:575px) {
                font-size: 20px;

            }
        }

        .modalHeadWrap {
            button {
                background: #009FAD;
                color: #fff;
                margin-right: 20px;
            }

        }

    }

    .sectionSwitch {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 20px 20px 20px;

        @media(min-width:320px)and (max-width:575px) {
            margin: 20px 10px 20px 10px;

        }

        p {
            margin-bottom: 0;
        }

        img {
            margin-left: 10px;

            @media(min-width:320px)and (max-width:575px) {
                margin-left: 0px;
                margin-right: 10px;

            }
        }

        .checkbox {

            input:checked~label:before,
            input:focus:checked~label:before {
                background-color: #009FAD !important;
            }
        }
    }

    .tabMenuItem {
        a {
            background-color: transparent;
            box-shadow: none;
            border-color: #E6E8EE !important;
            font-weight: 700;
            color: rgba(0, 0, 0, .95);
            width: 50%;
            text-align: center;
            display: flex;
            justify-content: center;
        }

        a.active {
            border-color: #009FAD !important;

        }

        .profileMain {
            display: flex;
            justify-content: space-around;
            margin-top: 30px;

            @media(min-width:320px)and (max-width:575px) {
                padding: 0px 10px;
            }

            .profileWrap {
                width: 80px;
                height: 80px;
                background: #ebe8e8;
                border-radius: 100%;
                position: relative;

                @media(min-width:320px)and (max-width:575px) {
                    width: 60px;
                    height: 60px;
                    margin-right: 5px;
                }

                .input {
                    width: 100%;
                    height: 100%;


                    input {
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        padding: 0;
                        z-index: 9999;
                    }

                    input ::placeholder {

                        color: red !important;

                    }

                    input:before {
                        height: 80px;
                        width: 80px;
                        border-radius: 100%;
                        display: flex;
                        text-align: center;
                        justify-content: center;
                        align-items: center;
                        background: #EEE9F8;
                        border: none;

                    }
                }
            }

            img {
                position: absolute;
                top: 26%;
                left: 30%;
                display: flex;
                align-items: center;
                justify-content: center;

                @media(min-width:320px)and (max-width:575px) {
                    left: 24%;
                    top: 24%;
                    width: 30px;

                }
            }

            .inputProfile {
                label {
                    color: #A4ABC4;
                    font-size: 12px;
                    font-weight: 600;
                }

                .input-wrapper {
                    margin-bottom: 10px;

                }

                input {
                    border-radius: 5px;

                    ::placeholder {
                        color: #000;
                    }
                }


            }
        }

        .companyWrap {
            // padding-bottom: 158px !important;
        }

        .companyDetail {
            padding: 20px 23px;

            .input-wrapper {
                margin-bottom: 10px;

                .input {
                    width: 100%;

                    input {
                        border-color: #A4ABC4;
                    }
                }

            }

            label {
                color: #A4ABC4;
                font-size: 12px;
                font-weight: 600;
            }

            .dropdown {
                border-color: #A4ABC4;
                margin-bottom: 10px;
                border-radius: 5px;
            }

            .react-tel-input {

                input {
                    margin-bottom: 10px !important;
                    margin-top: 0 !important;
                    border-color: #A4ABC4;
                    padding-left: 50px;

                }

                .flag-dropdown {
                    border-color: #A4ABC4;

                }
            }
        }
    }
}

.modalFilterMain {
    width: 200px !important;
    position: absolute;
    left: 0% !important;
    top: 0 !important;
    transform: translate(61%, 65%);

    .header.filterHeadercontent {
        border-bottom: 0 !important;
        padding: 20px 10px !important;

        .modalfilterWrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #E6E8EE;
            padding-bottom: 15px;

            .filterHead {
                display: flex;

            }



            h3 {
                font-size: 13px;
                margin-left: 10px;
                font-weight: 600;
            }
        }
    }

    .content {
        padding: 0px 10px !important;



        .filterForm {
            display: flex;



            label {
                padding-left: 20px;
                margin-right: 10px;
                font-size: 13px;
                font-weight: 500;

                :checked~.box:after,
                :checked~label:after {
                    background-color: #009FAD;

                }
            }


        }
    }

    .filterInput {
        padding: 0px 10px !important;

        .tagInput {
            div {
                span {
                    background: #A7B0FF;
                    color: #fff;
                    text-transform: capitalize;
                    padding: 1px 5px;

                    button {
                        color: #fff;
                    }
                }
            }
        }

        .input {
            width: 100%;

            input {
                padding: 7px 10px;
                margin-bottom: 15px;
            }
        }
    }

    .btnfilterOpen {
        // padding: 0px 10px;
        margin-bottom: 10px;
        width: 180px;
        margin-top: 10px;

        .button {
            width: 100%;
            background: #009FAD;
            color: #fff;
            text-transform: capitalize;
        }
    }
}

.modalFilterMain .filterInput .tagInput div span+input {
    display: none;
}

.ui.radio.checkbox input:checked~.box:before,
.ui.radio.checkbox input:checked~label:before {
    background-color: #009FAD;
}

.ui.radio.checkbox input:checked~.box:after,
.ui.radio.checkbox input:checked~label:after {
    background-color: #fff;
}

.ui.radio.checkbox input:focus:checked~.box:after,
.ui.radio.checkbox input:focus:checked~label:after {
    background-color: #fff;
}

.ui.radio.checkbox input:focus:checked~.box:before,
.ui.radio.checkbox input:focus:checked~label:before {
    background-color: #009FAD;
}

.filterfield {
    margin-right: 40px !important;
}

.popupImg {
    margin: 0px 10px;
}

.scheduleFilterWrap .ui.selection.dropdown .menu .item {
    margin-bottom: 10px;
}

@media(max-width:767px)and (min-width:320px) {
    .modals {
        .modalFilterMain {
            transform: translate(40%, 65%);

            .filterHeadercontent {
                padding: 20px 10px 0px 10px !important;
            }

            .content {
                .description {
                    padding: 0 !important;

                    .filterfield {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }


}

.segmentTextModal {
    width: 320px !important;
    padding: 20px;

    @media(max-width:375px) {
        width: 300px !important;

    }

    .segmentHead {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    button {
        background: #009FAD !important;
        color: #fff !important;
        margin-top: 20px !important;
    }
}

.taskTab {
    .logs-section-title {
        padding: 15px 15px !important;
    }

    .logs-section {
        display: flex;
        flex-flow: wrap;
        padding: 5px 15px;
        margin: 0px 10px;
        border-radius: 8px;

        .call-assign {
            width: 32%;
            display: flex;
            align-items: center;

            img {
                margin-right: 10px;
                width: 15px;
            }
        }

        .date-and-time {
            width: 32%;

            p {
                margin: 0;
            }
        }

        .assign-name {
            width: 32%;
        }
    }

    .taskSubmenu {
        display: flex;
        justify-content: space-between;
    }

    .taskHead {
        p {
            text-align: center;
            font-size: 12px;
            color: #A4ABC4;
            font-weight: 600;
            margin-top: 20px;
        }
    }

    .contact-card-boxes {
        .taskSubmenu {
            p {
                font-weight: 500 !important;
                text-transform: capitalize !important;
                font-size: 14px !important;

                span {
                    background: #5AB795;
                    color: #fff;
                    font-size: 10px;
                    padding: 2px 3px;
                    border-radius: 6px;
                    margin-left: 5px;
                }
            }

            .checkbox {
                label {
                    &::before {
                        width: 20px;
                        height: 20px;
                        border: 2px solid #A4ABC5;
                        border-radius: 4px;
                        opacity: 0.5;
                    }

                    &::after {
                        top: 1px;
                        left: 1px;
                        width: 19px;
                        height: 18px;
                        font-size: 14px;
                        background: #009FAD;
                        color: #fff;
                        border-radius: 4px;
                    }
                }
            }
        }
    }

    .addIcon {
        width: 48px;
        height: 48px;
        background: #009FAD;
        border-radius: 100%;
        margin: 60px 20px 20px auto;
        display: flex;
        align-items: center;
        justify-content: center;



        img {
            width: 20px !important;
        }
    }

    .card-boxes-text {
        .column {
            display: flex !important;
            align-items: center;

            img {
                width: 15px !important;
                margin-right: 10px !important;
            }
        }

    }


}

.noteTab {
    height: 450px !important;

    .noteLabel {
        display: flex;
        justify-content: space-between;

        label {
            font-size: 12px;
            color: #A4ABC4;
            font-weight: 500;
        }
    }


}

.addIcon {
    width: 48px;
    height: 48px;
    background: #009FAD;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    margin-top: 34px;
    margin-right: 20px;
    right: 0;
    transform: translate(-18px, -10px);


    img {
        width: 20px !important;
    }
}

.tagTab {
    .notebtn {
        display: flex;
        margin: 20px 10px;

        button {
            background: #E6E8EE;
            border: none;
            padding: 5px 10px;
            border-radius: 20px;
            display: flex;
            color: #262B3F;
            font-size: 12px;
            align-items: center;
            margin: 0px 4px;

            img {
                width: 10px !important;
                margin-left: 10px;
            }
        }
    }

    .addIcon {
        width: 48px;
        height: 48px;
        background: #009FAD;
        border-radius: 100%;
        margin: 380px 20px 20px auto;
        display: flex;
        align-items: center;
        justify-content: center;



        img {
            width: 20px !important;
        }
    }
}

.assignModal {
    width: 250px !important;
    padding: 10px;

    .assign-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid black;
        padding-bottom: 10px;
        margin: 0 -10px;

        img {
            height: 10px;
            width: 10px;
            margin-right: 10px;
        }
    }

    .header {
        font-size: 18px;
        margin-left: 10px;
    }

    .description {
        padding: 10px;
        width: 100%;
        margin-top: 10px;

        button {
            background: #009FAD;
            color: #fff !important;
            width: 100%;
            margin-top: 10px;
            border-radius: 8px;

            &:hover {
                background-color: #009FAD;
            }
        }

        label {
            font-size: 14px;
        }
    }

}

.profileDetailIcon {
    margin-left: 151px;
    height: 60px;
    width: 60px;
    background: #009FAD;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: #fff;
    font-weight: 500;
    text-transform: capitalize;
    // position: absolute;
    // top: 0;
    // left: 50%;
    // transform: translate(-35px, -50%);

    label {
        margin-bottom: 0;
    }
}

.associationsTab {
    background: #F5F5F5;
    padding-top: 1px;
    margin-top: -15px;
    overflow-y: scroll;
    height: 450px;

    .associationBoxes {
        background: #FFFFFF;
        border-radius: 8px;
        margin: 20px;
        padding: 15px;

        .associationWrap {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .profileContain {
                display: flex;
                align-items: center;

                .profileAssociation {
                    height: 50px;
                    width: 50px;
                    background: #009FAD;
                    border-radius: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 24px;
                    color: #fff;
                    font-weight: 500;
                    text-transform: capitalize;

                    label {
                        margin-bottom: 0;
                    }
                }

                .detailAssociation {
                    margin-left: 15px;

                    h4 {
                        font-size: 16px;
                        font-weight: 600;
                    }

                    p {
                        margin-bottom: 0;
                        font-size: 13px;
                    }
                }
            }

            .leadAssociation {
                .leadeDetail {
                    position: relative;

                    span {
                        width: 10px;
                        height: 10px;
                        background: #f76d8d;
                        position: absolute;
                        border-radius: 50px;
                        left: 0;
                        top: 0;
                        transform: translate(-13px, 5px);
                    }

                    label {
                        text-transform: uppercase;
                        margin-bottom: 0;
                    }
                }

                .leadIcon {
                    width: 30px;
                    height: 30px;
                    background: #e1e1e1;
                    border-radius: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 17px;
                    }
                }
            }

        }
    }


}

.AddBoxes {
    background: #FFFFFF;
    border-radius: 8px;
    // margin: 20px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;

    h3 {
        margin-bottom: 0;
        font-size: 20px;
        font-weight: 500;
    }

    .addIcons {
        width: 40px;
        height: 40px;
        background: #009FAD;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

}

.logTab {
    position: relative;

    .logs{
        .icon{
            height: 16px;
            width: 16px;
            margin-right: 15px;
            img{
                height: 100%;
                width: 100%;
            }
        }
        .date-content{
            color: #a4abc5;
            margin-left: -40px !important;
        }
        audio{
            height: 30px;
        }
        p{
            color: #a4abc5;
            margin-bottom: 0px;
            &:first-child{
                color: #000;
                font-weight: 400;
                font-size: 15px;
            }
        }
    }

    .logHead {
        text-align: center;
        margin-top: 15px;
        margin-bottom: -14px;

    }

    .logDetail {
        .logData {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        label {
            margin-bottom: 0;
        }

        img {
            width: 20px;
            height: 20px;
        }
    }

    .logWrap {
        position: fixed;
        background: #fff;
        padding: 40px;
        right: 0px;
        bottom: 0px;
        width: 100%;
        z-index: 11111;

        .addIcon {
            width: 48px;
            height: 48px;
            background: #009FAD;
            border-radius: 100%;
            display: -webkit-flex;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 0;
            bottom: 0;
            -webkit-transform: translate(-14px, 0px);
            transform: translate(-14px, -11px);

            img {
                width: 20px;
            }
        }
    }
}

.editModel {
    width: 390px !important;

    @media(max-width:424px) {
        width: 300px !important;
    }

    .editHead {
        display: flex !important;
        justify-content: space-between !important;
        align-items: center;

        h4 {
            margin-bottom: 0;
            font-weight: 600;
        }

        img {
            width: 20px;
        }
    }

    .fromEdit {
        padding: 10px 20px 20px 20px;


        .field {
            label {
                margin-top: 20px;
                font-weight: 600;
            }

            .react-tel-input input {
                margin-top: 0 !important;
                padding: 10px 10px 10px 50px;
            }
        }

        .btnMain {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;

            button {
                border-radius: 30px;
                width: 120px;
                font-weight: bold;
                padding: 15px 10px;
            }

            .btnSave {
                background: #009FAD;
                color: #fff;

            }
        }
    }
}
.btn-close {
    padding: 10px 20px;
    font-family: $code-font-stack;
    font-weight: 300;
    color: #fff;
    text-transform: capitalize;
    background: $link-color;
    border: 1px solid $link-color;
    letter-spacing: 1px;
    font-size: 14px;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $link-color;

      background: transparent;
    }
  }

ul.react-autosuggest__suggestions-list {
    list-style: none;   
    border: 1px solid #d4d4d4;
    border-bottom: none;
    padding-left: 0px;
    padding-right: 0px;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
  
  }

  ul.react-autosuggest__suggestions-list > li {
    list-style: none;  
  }
  
  .autocomplete-items {
    padding: 10px;
    cursor: pointer;
    background-color: #d4d4d4; 
    border-bottom: 1px solid #d4d4d4; 
  }
  
  /*when hovering an item:*/
  .autocomplete-items:hover {
    background-color: #e9e9e9; 
  }

  .segmentbtn {
    border-radius: 4px;
    background: #fff;
    padding: 11px 7px;
    font-size: 14px;
    color: #009FAD !important;
    cursor: pointer;
    margin-right: 20px;   
    border: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    text-transform: capitalize;
    img {
        margin-right: 5px;
        padding-left: 0;
    }
    label {
        margin: 0px 5px 0px 5px;
    }
    }



  .btn-export {
    padding: 10px;
    width: 140px;
  }

  .btn-export {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 26px;
    width: 167px;

    font-size: 14px;
    font-weight: 600;
    color: $white;
    text-transform: uppercase;
    background-color: $green;
    border: 1px solid $green;

    &:hover {
      color: $white;
      background: $btn-green-hover;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
    }

    img {
      margin-right: 12px;
      height: 16px;
    }

    &:before {
      content: '\f078';

      position: absolute;
      top: 50%;
      right: 10px;

      font-family: 'Font Awesome 5 Free';
      font-weight: 600;

      transform: translateY(-50%);
    }
  }

.lists-table {
    .edit-icon {
        margin-left: 4px;
    }
    .edit-icon, .delete-icon {
        display: none;
        cursor: pointer;
    }
    &:hover {
        .edit-icon, .delete-icon {
            display: inline-block;
        }
    }
}
