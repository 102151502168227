.account-container{
    width: calc(100% - $nav-width);
    margin-left: auto;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    line-height: 1.5 !important;
    letter-spacing: 0.00938em !important;
    padding: 80px 20px 20px;
    background: #f2f2f2;
    .search-bar{
        display: flex;
        justify-content: space-between;
        background: #ffffff;
        padding: 10px;
        input{
            padding: 7px 10px;
            width: 100%;
            max-width: 500px;
            border: 1px solid #a7a7a7;
            border-radius: 5px;
        }
    }
    .table-responsive{
        overflow: auto;
        table{
            min-width: 1700px;
            th{
                width: 10%;
                &:nth-child(7){
                    width: 18%;
                } 
                &:nth-child(5),    
                &:nth-child(6),    
                &:nth-child(8){
                    width: 6%;
                }       
                &:nth-child(9),
                &:nth-child(10){
                    width: 12%;                    
                }
            }
        }
    }
    .dropdown-column{
        height: 100%;
        background: #ffffff;
        padding: 10px;
        >.dropdown{
            margin: 0 10px 10px !important;
            height: max-content !important;
            padding: 10px 20px;
            border: 1px solid #a7a7a7;
        }
    }
}
.visited-page {
    height: fit-content;
    width: fit-content;
    background-color: lightgray;
    padding: 3px;
    border-radius: 25px;
    margin: 5px 0;
}
.apexcharts-toolbar{
    display: none !important;
}

.segment-main{
        height: 100%;
            background: #ffffff;
            .segment-list{
                padding: 15px;
                    border-bottom: 1px solid lightgray;
            }
}
.segment-auto{
    .MuiFilledInput-root{
        background-color: #ffffff;
    }
}
.show-expand{
  color: #6938ef;
        svg{
            fill:#6938ef
        }
}