.compliance-container{
    margin-left: $nav-width;
    .compliance-card{
        padding: 16px;
        background-color: white;
        border-radius: 12px;
        border: 1px solid rgb(171, 171, 171);
        display: flex;
    }
    .img-wrapper{
           max-width: 300px;
            width: calc(100% + 32px);
            margin: -16px;
            padding: 16px;
            margin-right: 16px;
            background-color: #ffffef;
            text-align: center;
            display: flex;
            align-items: center;
            border-radius: 12px 0 0 12px;
            img{
                width: auto;
                max-height: 190px;
                height: 100%;
                margin: auto;
            }
        }
        a{
            text-decoration: underline;
        }
    .left-part{
        flex: 0 0 auto;
        width: 350px;
        .warpper{
            height: 80px;
            width: 100%;
            border-radius: 8px;
            background-color: rgb(255, 233, 233);
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .MuiIconButton-root{
            height: 40px;
            width: 40px;
            border-radius: 100%;
            background-color: red;
            color: white;
        }
    }
    .right-part{
        flex:1;
        li {
            font-weight: normal;
        }
    }
    .desc{
        font-size: 14px;
        color: #6f6f6f;
    }
    .md-head{
        font-size: 18px;
        color: #000;
    }
}
